import { trpc } from "@/trpc"
import type { Origin, SupportedCurrencyCode } from "@/typings"
import { formatErrorDetails } from "@/utils/error-logs"
import { sentryCaptureException } from "@/utils/utils"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"

export const useRedirectChargeBeePortal = (
	defaultCurrency: SupportedCurrencyCode,
	origin: Origin,
) => {
	const { mutateAsync } = trpc.billing.createPortalSession.useMutation()

	return useMutation({
		mutationFn: async (currency: SupportedCurrencyCode = defaultCurrency) => {
			const response = await mutateAsync({
				currency,
				origin,
			})
			return response
		},
		onSuccess: ({ isSuccess, data, message }) => {
			if (!isSuccess) {
				toast.error(message)
				return
			}

			if (data) {
				window.location.assign(data)
				return
			}

			toast.error(
				"Something went wrong while redirecting to subscription portal. ",
			)
		},
		onError: error => {
			toast.error("Something went wrong!!")
			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				"redirectChargebeePortal: API failed: ",
				errorDetails,
			)
		},
	})
}
