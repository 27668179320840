import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import { TabsContent } from "@radix-ui/react-tabs"
import { CalendarIcon } from "lucide-react"
import moment from "moment"
import type { DateRange } from "react-day-picker"

const rangeOptions = [
	{ value: "1", label: "1 day" },
	{ value: "7", label: "7 days" },
	{ value: "14", label: "14 days" },
	{ value: "30", label: "30 days" },
	{ value: "custom", label: "Custom" },
] as const

const isRangeOption = (
	value: unknown,
): value is (typeof rangeOptions)[number]["value"] => {
	return rangeOptions
		.map(option => option.value)
		.includes(value as (typeof rangeOptions)[number]["value"])
}

interface ReportRangeSelectorProps {
	readonly range: DateRange | undefined
	readonly setRange: (range: DateRange | undefined) => void
	readonly disabled?: boolean
}

const getCurrentTabValue = (range: DateRange | undefined) => {
	if (!range?.from || !range.to) return "custom"
	const today = moment().startOf("day")
	const isEndingToday = moment(range.to).startOf("day").isSame(today)
	if (!isEndingToday) return "custom"
	const diffDays = moment(range.to)
		.startOf("day")
		.diff(moment(range.from).startOf("day"), "days")
	const matchingOption = rangeOptions.find(
		option => option.value !== "custom" && Number(option.value) === diffDays,
	)
	return matchingOption?.value ?? "custom"
}

export const ReportRangeSelector: React.FC<ReportRangeSelectorProps> = ({
	range,
	setRange,
	disabled,
}) => {
	return (
		<div className="font-poppins">
			<div>
				<h4 className="text-base font-medium">Date Range</h4>
				<p className="text-sm text-muted-foreground">
					Select a date range to include your pull request activity.
				</p>
			</div>
			<div className="mt-4">
				<Tabs
					defaultValue="7"
					value={getCurrentTabValue(range)}
					onValueChange={value => {
						if (!isRangeOption(value)) return
						if (value === "custom") {
							setRange(undefined)
							return
						}
						setRange({
							from: moment(new Date()).subtract(Number(value), "days").toDate(),
							to: new Date(),
						})
					}}
					className="w-full"
				>
					<TabsList>
						{rangeOptions.map(option => (
							<TabsTrigger
								key={option.value}
								value={option.value}
								disabled={disabled}
							>
								{option.label}
							</TabsTrigger>
						))}
					</TabsList>
					{rangeOptions.map(option => (
						<TabsContent
							key={option.value}
							className="mt-4"
							value={option.value}
						>
							<div className={cn("grid gap-2")}>
								<Popover>
									<PopoverTrigger asChild disabled={disabled}>
										<Button
											id="date"
											variant={"outline"}
											className={cn(
												"flex w-1/2 justify-start gap-x-2 bg-white text-left font-normal",
												!range && "text-muted-foreground",
											)}
										>
											<CalendarIcon />
											{range?.from && range.to && (
												<>
													{moment(range.from).format("DD MMM YYYY")} -{" "}
													{moment(range.to).format("DD MMM YYYY")}
												</>
											)}
											{range?.from &&
												!range.to &&
												moment(range.from).format("DD MMM YYYY")}
											{!range?.from && !range?.to && (
												<span>Select a range</span>
											)}
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" align="start">
										<Calendar
											mode="range"
											defaultMonth={range?.from ?? new Date()}
											selected={range}
											onSelect={setRange}
											numberOfMonths={2}
											disabled={date => date > new Date()}
											max={60}
										/>
									</PopoverContent>
								</Popover>
							</div>
						</TabsContent>
					))}
				</Tabs>
			</div>
		</div>
	)
}
