import type { FormData } from "@/components/AnalyticsPopup/Popup.tsx"
import ButtonRegular from "@/components/ButtonRegular/ButtonRegular"
import { SanitizedHTML } from "@/components/Toast/Toast"
import { DataTable } from "@/components/ui/data-table"
import { logger } from "@/lib/utils"
import AzureBotUserModal from "@/pages/Settings/AzureUser/AzureBotUserModal.tsx"
import BitbucketBotUserModal from "@/pages/Settings/BitbucketUser/BitbucketBotUserModal.tsx"
import ChooseBotUserModal from "@/pages/Settings/GitlabUser/ChooseBotUserModal.tsx"
import { trpc } from "@/trpc"
import type { OrganizationData } from "@/typings"
import { formatErrorDetails } from "@/utils/error-logs"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { PROVIDERS, useProvider, type Provider } from "@/utils/providers"
import { handleSessionExpiration } from "@/utils/session.ts"
import {
	checkIfHigherRoleInGitLab,
	getCookie,
	getSelectedOrg,
	isTRPCClientError,
	sentryCaptureException,
} from "@/utils/utils.ts"
import type { PaginationState, RowSelectionState } from "@tanstack/react-table"
import axios, { AxiosError } from "axios"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PopupForm from "../../../components/AnalyticsPopup/Popup"
import ImageButton from "../../../components/ImageButton/ImageButton"
import Search from "../../../components/Search/Search"
import { columns } from "./Columns"
import { GitlabGroupSelector } from "./GitlabGroupSelector"

const REPOSITORIES_PER_PAGE = 10

export type SelectedGitlabGroup =
	| {
			id: OrganizationData["id"]
			name: string
			path: OrganizationData["organization_full_path"]
	  }
	| undefined

function getOrgIds({
	provider,
	selectedGroup,
}:
	| {
			provider: Extract<Provider, "azure-devops">
			selectedGroup: OrganizationData["id"] | undefined
	  }
	| {
			provider: Extract<Provider, "gitlab">
			selectedGroup: SelectedGitlabGroup
	  }) {
	if (provider === "gitlab") {
		return (selectedGroup ? [selectedGroup.id] : []).toString()
	}

	return (selectedGroup ? [selectedGroup] : []).toString()
}

function getSubgroupName(
	subGroupName: string,
	subGroupFullPath: string,
	orgName?: string,
) {
	return orgName === subGroupName ? "Base group" : subGroupFullPath
}

export default function RepositoryList() {
	const [isLoadingRepos, setIsLoadingRepos] = useState<boolean>(false)
	const [isLoadingOrgs, setIsLoadingOrgs] = useState<boolean>(false)
	const [, setLoaderMessage] = useState<string>("")
	const isRecentlyCreated =
		sessionStorage.getItem("recently_created") === "true"
	const navigate = useNavigate()

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

	const {
		provider,
		isGitHub,
		isGitHubCloud,
		isGitlab,
		isSelfHosted,
		isGitlabCloud,
		isAzureDevops,
		isBitbucket,
	} = useProvider()

	const user_id = sessionStorage.getItem("user_id")
	const subscriber_id = sessionStorage.getItem("subscriber_id")
	const host_url = sessionStorage.getItem("host_url")

	const signUpCompleted = trpc.mixpanel.signUpCompleted.useMutation()

	const { organizations, fetchOrganizations } = useAllOrgs()

	const selectedOrg = getSelectedOrg()

	const [popupWasOpened, setPopupWasOpened] = useState(
		sessionStorage.getItem("popupWasOpened") === "true" || false,
	)

	const [popupOpen, setPopupOpen] = useState(
		isRecentlyCreated && isGitlab && !popupWasOpened,
	)
	const [chargebeeEmail, setChargebeeEmail] = useState<string>("")

	const [gitlabUserPopup, setGitlabUserPopup] = useState(false)
	const [azureUserPopup, setAzureUserPopup] = useState(false)
	const [bitbucketUserPopup, setBitbucketUserPopup] = useState(false)

	const updateContactSignUpCompleted =
		trpc.hubspot.updateContactSignUpCompleted.useMutation()
	const updateHearAbout = trpc.hubspot.updateContactHearAbout.useMutation()
	const updateTwitter = trpc.twitter.updateAnalytics.useMutation()

	const [refetchGitlabUser, setRefetchGitlabUser] = useState(false)

	const addGitLabRepositories =
		trpc.repositories.addGitLabRepositories.useMutation({
			onSuccess: async () => {
				await repositoriesQuery.refetch({
					refetchPage: (_lastPage, index, _allPages) => {
						if (index === pagination.pageIndex) {
							return true
						}
						return false
					},
				})
				toast.success("Repository added successfully")
			},
			onError: error => {
				if (error.data?.httpStatus == 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.httpStatus == 403) {
					toast.error(
						"Error: Only Maintainers or Owners can install CodeRabbit on this repository.",
					)
					return
				}
				if (
					error.data?.httpStatus == 400 &&
					error.message.includes("is not linked to a SAML")
				) {
					const htmlContent = `User is not linked to a SAML account or has an inactive SCIM identity. For information on how to resolve this error, click <a href="https://gitlab.com/help/user/group/saml_sso/troubleshooting_scim" target="_blank" rel="noopener noreferrer">here for the troubleshooting SCIM documentation</a>.`

					toast.error(<SanitizedHTML content={htmlContent} />, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: 0,
					})
					return
				}
			},
		})

	const addAzureDevOpsRepositories =
		trpc.repositories.addAzureDevOpsRepository.useMutation({
			onSuccess: async () => {
				await repositoriesQuery.refetch({
					refetchPage: (_lastPage, index, _allPages) => {
						if (index === pagination.pageIndex) {
							return true
						}
						return false
					},
				})
				toast.success("Repository added successfully")
			},
			onError: error => {
				if (error.data?.httpStatus == 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.httpStatus == 403) {
					toast.error(
						"Error: Only Maintainers or Owners can install CodeRabbit on this repository.",
					)
					return
				}
			},
		})

	const addBitbucketRepositories =
		trpc.repositories.addBitbucketRepository.useMutation({
			onSuccess: async () => {
				await repositoriesQuery.refetch({
					refetchPage: (_lastPage, index, _allPages) => {
						if (index === pagination.pageIndex) {
							return true
						}
						return false
					},
				})
				toast.success("Repositories added successfully")
			},
			onError: error => {
				if (error.data?.httpStatus == 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.httpStatus == 403) {
					toast.error(
						"Error: Only Maintainers or Owners can install CodeRabbit on this repository.",
					)
					return
				}
			},
		})

	const gitlabUserQuery = trpc.providers.getOrgUser.useQuery()

	const azureUserQuery = trpc.providers.getOrgUser.useQuery(undefined, {
		enabled: isAzureDevops && !(isLoadingRepos || isLoadingOrgs),
	})

	const azureUser = azureUserQuery.data
	const gitlabBotUser = gitlabUserQuery.data

	const bitbucketUserQuery = trpc.providers.getOrgUser.useQuery(undefined, {
		enabled: isBitbucket && !(isLoadingRepos || isLoadingOrgs),
	})
	const bitbucketUser = bitbucketUserQuery.data

	const { data: user } = trpc.users.getUser.useQuery(
		{
			provider_user_id: user_id || "",
			provider: provider || "",
			host_url: host_url || undefined,
		},
		{
			enabled:
				!!user_id &&
				!!provider &&
				isRecentlyCreated &&
				isGitlab &&
				!popupWasOpened,
		},
	)

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: REPOSITORIES_PER_PAGE,
	})

	const [selectedGroup, setSelectedGroup] = useState<OrganizationData["id"]>()

	const [selectedGitlabGroup, setSelectedGitlabGroup] =
		useState<SelectedGitlabGroup>()

	const [searchQuery, setSearchQuery] = useState<string>("")

	const repositoriesQuery = trpc.repositories.getAllRepos.useQuery(
		{
			provider: provider || "",
			orgName: selectedOrg?.organization_name || "",
			type: selectedOrg?.type || "",
			orgIds: getOrgIds(
				isGitlab
					? { provider: "gitlab", selectedGroup: selectedGitlabGroup }
					: { provider: "azure-devops", selectedGroup },
			),
			selfHostedDomain: sessionStorage.getItem("selfHostedDomain") ?? undefined,
			page: pagination.pageIndex + 1,
			perPage: pagination.pageSize,
			searchQuery,
		},
		{ enabled: !!selectedOrg },
	)

	const subGroupsQuery = trpc.repositories.getAllSubGroups.useQuery(
		{
			baseGroupId: selectedOrg?.provider_organization_id ?? "",
		},
		{
			enabled: false,
		},
	)

	const repos = repositoriesQuery.data?.repos ?? []
	const total = repositoriesQuery.data?.total ?? 0
	const subGroups = subGroupsQuery.data ?? []

	useEffect(() => {
		if (import.meta.env.PROD && organizations.length) {
			const subscriber_id = sessionStorage.getItem("subscriber_id")
			const user_name = sessionStorage.getItem("login")
			const email = user?.data.email

			if (subscriber_id && provider && user_name) {
				const orgIds = organizations.map(org => org.id)
				identifyUserMutation.mutate({
					user_id: subscriber_id,
					provider: provider,
					scope: getSelectedOrg()?.type || "",
					name: user_name,
					email: email || "",
					orgIds: orgIds,
				})
			}
		}
	}, [organizations])

	useEffect(() => {
		// Fetch orgs and repos on initial load
		// For GitLab, repos will be fetched in the useEffect below this one
		void fetchData({ fetchOrgs: true, fetchRepos: false })
	}, [])

	useEffect(() => {
		if (isGitlab && selectedGitlabGroup) {
			// Refetch repos for gitlab when the selected group or page changes
			void fetchData({ fetchOrgs: false, fetchRepos: true })
		}
	}, [selectedGitlabGroup, pagination.pageIndex, pagination.pageSize])

	useEffect(() => {
		if (
			isRecentlyCreated &&
			isGitlabCloud &&
			popupWasOpened &&
			sessionStorage.getItem("gitlab-user-popup") !== "false"
		) {
			setGitlabUserPopup(true)
		}
	}, [isRecentlyCreated, isGitlabCloud, popupWasOpened])

	useEffect(() => {
		if (isAzureDevops && azureUserQuery.isFetched && !azureUserQuery.isError) {
			setAzureUserPopup(!azureUser)
		}
	}, [isAzureDevops, azureUserQuery.isFetched, azureUser])

	useEffect(() => {
		if (
			isBitbucket &&
			bitbucketUserQuery.isFetched &&
			!bitbucketUserQuery.isError
		) {
			setBitbucketUserPopup(!bitbucketUser)
		}
	}, [isBitbucket, bitbucketUserQuery.isFetched, bitbucketUser])

	useEffect(() => {
		if (user?.data.email) {
			setChargebeeEmail(user.data.email)
		}
	}, [user])

	useEffect(() => {
		if (refetchGitlabUser) {
			void gitlabUserQuery.refetch()
			setRefetchGitlabUser(false)
		}
	}, [refetchGitlabUser])

	const handleClosePopup = () => {
		setPopupOpen(false)
		setPopupWasOpened(true)
		sessionStorage.setItem("popupWasOpened", "true")
	}

	// function getSubgroupName(
	// 	subGroupName: string,
	// 	subGroupFullPath: string,
	// 	orgName?: string,
	// ) {
	// 	if (isGitlab) {
	// 		if (orgName === subGroupName) {
	// 			return "Base group"
	// 		}
	// 		// if the subgroup has the format "x/y/z", only return the last 2 paths in the format ".../y/z"
	// 		const subGroupPaths = subGroupFullPath.split("/")
	// 		if (subGroupPaths.length > 2) {
	// 			return `.../${subGroupPaths.slice(-2).join("/")}`
	// 		}
	// 	}
	// 	return subGroupFullPath
	// }

	/**
	 * Get all repoes
	 * */
	async function fetchRepos() {
		if (isGitlab && !selectedGitlabGroup) {
			return
		}
		setIsLoadingRepos(true)
		const selectedOrganization = getSelectedOrg()
		if (!selectedOrganization) {
			sessionStorage.clear()
			navigate("/login")
			return
		}

		try {
			await repositoriesQuery.refetch({
				refetchPage: (_lastPage, index, _allPages) => {
					if (index === pagination.pageIndex) {
						return true
					}
					return false
				},
			})

			const shouldRedirect = sessionStorage.getItem("shouldRedirect")

			if (
				provider == "github" &&
				shouldRedirect == "true" &&
				isRecentlyCreated
			) {
				window.location.assign(import.meta.env.VITE_GITHUB_APP_URL)
				setIsLoadingRepos(false)
				sessionStorage.removeItem("app-status")
				return
			}

			// fetch subgroups
			if (isAzureDevops && selectedOrg?.provider_organization_id) {
				await subGroupsQuery.refetch()
			}
		} catch (error: unknown) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.response?.data.message === "OAUTH_ACCESS_DENIED") {
					toast.error(
						"Your organization has disabled access via OAuth. Please check your organization policies and enable third-party application access via OAuth",
					)
				}
			} else if (error instanceof Error) {
				// Handle non-Axios errors if necessary
				toast.error("An unexpected error occurred. Please try again.")
				logger.error("Unexpected error:", error)
			} else {
				logger.error("Unknown error:", error)
			}
		} finally {
			setIsLoadingRepos(false)
		}
	}

	function handleSearch(searchQuery: string) {
		setSearchQuery(searchQuery)
	}

	const identifyUserMutation = trpc.mixpanel.identifyUser.useMutation()

	async function fetchData({
		fetchOrgs = true,
		fetchRepos: fetchRepositories = true,
	}) {
		try {
			if (fetchOrgs) {
				setIsLoadingOrgs(true)
				setLoaderMessage("Fetching repositories... 🚀")

				await fetchOrganizations(true).catch(e => {
					if (isTRPCClientError(e)) {
						if (e.data?.code === "UNAUTHORIZED") {
							handleSessionExpiration(navigate)
							return
						}
					}
					toast.error("Something went wrong, Please try again")
				})

				// If GitLab, set the selected group once orgs are fetched.
				if (isGitlab && selectedOrg !== null) {
					setSelectedGitlabGroup({
						id: selectedOrg.provider_organization_id,
						name: selectedOrg.organization_name,
						path: selectedOrg.organization_full_path,
					})
				}

				if (selectedOrg && isGitHub) {
					// Only set redirect for GitHub providers
					const shouldRedirect = selectedOrg.lastSubscriptionStatus === null // or any logic you need
					sessionStorage.setItem("shouldRedirect", shouldRedirect.toString())
				}

				setIsLoadingOrgs(false)
			}

			if (fetchRepositories) {
				await fetchRepos()
			}
		} catch (error) {
			logger.error("🚀 ~ file: Repository.tsx ~ fetchData ~ error:", error)
		}
	}

	// First, create the base group array from the selected organization
	const baseGroup = selectedOrg ? [selectedOrg] : []

	// Combine base group with subgroups for the complete list
	let groupList = [...baseGroup, ...subGroups]

	if (provider === PROVIDERS.AZURE_DEVOPS) {
		groupList = subGroups
	}

	groupList.sort((a, b) => {
		const toCompareA = a.organization_full_path
			? a.organization_full_path
			: a.organization_name
		const toCompareB = b.organization_full_path
			? b.organization_full_path
			: b.organization_name
		return toCompareA.localeCompare(toCompareB)
	})

	const isSaveEnabled = useMemo(() => {
		// Filter the data to include only rows that are not installed
		const rowsNotInstalled = repos.filter(repo => !repo.isEnabled)
		// Check if any of the not installed rows are selected
		return rowsNotInstalled.some(repo => rowSelection[String(repo.id)])
	}, [rowSelection, repos])

	const pageCount = Math.ceil(total / pagination.pageSize)

	const updateContact = trpc.hubspot.updateContactCompany.useMutation()

	async function handleFormDataSubmit(formData: FormData) {
		if (subscriber_id && provider)
			await signUpCompleted
				.mutateAsync({
					user_id: subscriber_id,
					provider: provider,
					workEmail: formData.email,
					selfHostedDomain: host_url || "",
					scope: selectedOrg?.type || "",
					orgName: selectedOrg?.organization_name || "",
					orgId: selectedOrg?.id || "",
					memberCount: selectedOrg?.memberCount || 0,
				})
				.catch(err => {
					const errorDetails = formatErrorDetails(err)
					sentryCaptureException(
						"handleFormDataSubmit: signUpCompleted API failed on Installation Message",
						errorDetails,
					)
				})

		await updateContactSignUpCompleted
			.mutateAsync({
				email: formData.email,
			})
			.catch(err => {
				const errorDetails = formatErrorDetails(err)
				sentryCaptureException(
					"handleFormDataSubmit: updateSignUpCompleted API failed",
					errorDetails,
				)
			})

		const twclid = getCookie("twclid")

		if (twclid) {
			await updateTwitter
				.mutateAsync({
					twclid: twclid,
					isOrg: selectedOrg?.type === "Organization",
				})
				.catch(err => {
					const errorDetails = formatErrorDetails(err)
					sentryCaptureException(
						"handleFormDataSubmit: updateTwitter API failed",
						errorDetails,
					)
				})
		}

		const company = formData.work
		if (!company) {
			return
		}
		try {
			await updateContact.mutateAsync({
				email: chargebeeEmail,
				company: company,
			})
		} catch (err) {
			const errorDetails = formatErrorDetails(err)
			sentryCaptureException(
				"handleFormDataSubmit: updateContactCompany API failed",
				errorDetails,
			)
		}

		await updateHearAbout
			.mutateAsync({
				email: chargebeeEmail,
				hearAbout: formData.hearAbout,
				other: formData.specificSource,
			})
			.catch(err => {
				const errorDetails = formatErrorDetails(err)
				sentryCaptureException(
					"handleFormDataSubmit: updateContactHearAbout API failed",
					errorDetails,
				)
			})

		if (formData.email === chargebeeEmail) {
			return
		}

		setChargebeeEmail(formData.email)

		await axios
			.post(`${import.meta.env.VITE_BILLING_FUNC_URL}/updateOrgEmail`, {
				email: formData.email,
				organization_id: sessionStorage.getItem("org_id"),
			})
			.catch(err => {
				const errorDetails = formatErrorDetails(err)
				sentryCaptureException(
					"handleFormDataSubmit: updateEmail API failed",
					errorDetails,
				)
			})
	}

	function updateAzureToken(popupState: boolean) {
		setAzureUserPopup(popupState)
	}

	async function handleInstallingGitLabOrAzureRepositories() {
		if (isGitlab && !gitlabBotUser) {
			setGitlabUserPopup(true)
			return
		}

		if (isAzureDevops && !azureUser) {
			setAzureUserPopup(true)
			return
		}

		if (isBitbucket && !bitbucketUser) {
			setBitbucketUserPopup(true)
			return
		}

		// Filter the data to include only rows that are not installed
		const rowsNotInstalled = repos.filter(repo => !repo.isEnabled)

		const selectedRepoIds = Object.keys(rowSelection).filter(
			id => rowSelection[id],
		)

		const selectedRepos = rowsNotInstalled.filter(repo =>
			selectedRepoIds.includes(String(repo.id)),
		)

		const repositories = selectedRepos.map(repo => ({
			id: repo.id,
			name: repo.name,
			orgId: repo.orgId,
			orgName: selectedOrg?.organization_name || "",
			private: repo.private,
		}))

		const login = sessionStorage.getItem("login")
		if (!user_id || !login) {
			logger.error("user_id or login not found")
			toast.error(
				"Installation failed due to missing session information. Please log out and log back in to continue.",
			)
			return
		}

		if (isGitlab) {
			await addGitLabRepositories.mutateAsync({
				repositories: repositories,
				requester: {
					id: user_id,
					login: login,
				},
			})
		} else if (isAzureDevops) {
			await addAzureDevOpsRepositories.mutateAsync({
				repositories: repositories,
				requester: {
					id: user_id || "",
					login: sessionStorage.getItem("login") || "",
				},
			})
		} else if (isBitbucket) {
			await addBitbucketRepositories.mutateAsync({
				repositories: repositories,
				requester: {
					id: user_id || "",
					login: sessionStorage.getItem("login") || "",
				},
			})
		}
	}

	async function updateBitbucketToken(popupState: boolean) {
		if (!popupState) {
			await bitbucketUserQuery.refetch()
		}

		setBitbucketUserPopup(popupState)
	}

	const isLoading =
		isLoadingRepos || isLoadingOrgs || repositoriesQuery.isLoading

	return (
		<>
			<div className="container mx-auto px-8 pb-2 pt-7">
				<div className="w-full">
					<div className="flex items-center justify-between">
						<div>
							<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
								Repositories
							</div>
							<div className="font-400 font-inter text-sm leading-5 text-muted-foreground">
								List of repositories accessible to CodeRabbit.
							</div>
						</div>

						{isGitHubCloud && (
							<a href={import.meta.env.VITE_GITHUB_APP_URL} rel="noreferrer">
								<ImageButton title="Add Repositories" />
							</a>
						)}

						{((isGitlab && checkIfHigherRoleInGitLab(selectedOrg?.role)) ||
							isAzureDevops ||
							isBitbucket) && (
							<ButtonRegular
								title="Install Repositories"
								disabled={!isSaveEnabled}
								onClick={handleInstallingGitLabOrAzureRepositories}
								loading={
									addGitLabRepositories.isLoading ||
									addAzureDevOpsRepositories.isLoading ||
									addBitbucketRepositories.isLoading
								}
							/>
						)}
					</div>

					<div className="mb-4 mt-6 flex w-full justify-between gap-2 md:gap-4">
						<div className="flex-1">
							<Search
								onSearch={handleSearch}
								placeholder="Repo not found? Search here…"
								showLabel={false}
								debounce
								defaultValue={searchQuery}
								disabled={isLoading}
							/>
						</div>
						{isAzureDevops && groupList.length > 1 && (
							<div className="flex-1 lg:flex-initial">
								<div className="font-poppins text-sm text-crb-text-secondary">
									Projects
								</div>
								<select
									id="group"
									name="group"
									value={selectedGroup}
									onChange={e => {
										setSelectedGroup(e.target.value)
										setPagination(prev => ({ ...prev, pageIndex: 0 }))
									}}
									className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 font-figtreeRegular font-normal shadow-sm transition placeholder:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 lg:w-64"
								>
									{groupList.map(org => {
										return (
											<option
												value={org.provider_organization_id}
												key={org.provider_organization_id}
											>
												{getSubgroupName(
													org.organization_name,
													org.organization_full_path,
													selectedOrg?.organization_name,
												)}
											</option>
										)
									})}
								</select>
							</div>
						)}
						{isGitlab && (
							<div className="flex-1 lg:flex-initial">
								<div className="mb-1 font-poppins text-sm text-crb-text-secondary">
									Group
								</div>
								<GitlabGroupSelector
									value={selectedGitlabGroup}
									onSelect={selectedGitlabGroup => {
										setSelectedGitlabGroup(selectedGitlabGroup)
										setPagination(prev => ({ ...prev, pageIndex: 0 }))
									}}
								/>
							</div>
						)}
					</div>

					<DataTable
						data={repos}
						isLoading={isLoading}
						columns={columns({
							isGitLab: isGitlab,
							isAzureDevops: isAzureDevops,
							isBitbucket: isBitbucket,
							role: selectedOrg?.role,
						})}
						tableOptions={{
							manualPagination: true,
							pageCount,
							state: {
								pagination,
								rowSelection,
							},
							onPaginationChange: setPagination,
							onRowSelectionChange: setRowSelection,
							getRowId: originalRow => String(originalRow.id),
						}}
					/>
				</div>

				{popupOpen && (
					<PopupForm
						onClose={handleClosePopup}
						onSubmit={handleFormDataSubmit}
						email={chargebeeEmail}
						work={
							selectedOrg?.type === "Organization"
								? selectedOrg.organization_name
								: ""
						}
						step={isSelfHosted ? 2 : 1}
					/>
				)}

				{gitlabUserPopup && (
					<ChooseBotUserModal
						open={gitlabUserPopup}
						onOpenChange={state => {
							sessionStorage.setItem("gitlab-user-popup", state.toString())
							setGitlabUserPopup(state)
							setRefetchGitlabUser(true)
						}}
					/>
				)}

				{azureUserPopup && (
					<AzureBotUserModal
						open={azureUserPopup}
						onOpenChange={state => {
							updateAzureToken(state)
						}}
					/>
				)}

				{bitbucketUserPopup && (
					<BitbucketBotUserModal
						open={bitbucketUserPopup}
						onOpenChange={state => updateBitbucketToken(state)}
					/>
				)}
			</div>
		</>
	)
}
