import { trpc } from "@/trpc"
import type { SidebarMenuItem } from "./Nav"
import { useSelectedOrg } from "./useSelectedOrg"

export function isNotHidden(
	item: NonNullable<SidebarMenuItem["items"]>[number] | SidebarMenuItem,
): boolean {
	return !item.hide
}

export function checkIsActive(
	item: SidebarMenuItem,
	pathname: string,
): boolean {
	if (typeof item.link === "string" && pathname.startsWith(item.link)) {
		return true
	}

	for (const altItem of item.alt ?? []) {
		if (pathname.startsWith(altItem.link)) {
			return true
		}
	}

	for (const subItem of item.items ?? []) {
		if (pathname.startsWith(subItem.link)) {
			return true
		}
	}

	return false
}

export const useCheckProPlanLock = () => {
	const selectedOrg = useSelectedOrg()
	const req = trpc.subscriptions.checkProPlanLock.useQuery(undefined, {
		enabled: !!selectedOrg?.id,
	})
	return {
		isLoading: req.isLoading && !!selectedOrg?.id,
		proPlanLock: req.data?.isLock ?? true,
	}
}
