import { trpc } from "@/trpc"
import { formatErrorDetails } from "@/utils/error-logs"
import { getCookie, sentryCaptureException } from "@/utils/utils.ts"
import type { Dispatch, SetStateAction } from "react"
import { toast } from "react-toastify"

export function useEmailSubmitHandler() {
	const addMobileContact = trpc.hubspot.addMobileContact.useMutation()

	async function handleEmailSubmit(
		email: string,
		isValidEmail: boolean,
		setShowEmailInput: Dispatch<SetStateAction<boolean>>,
		setIsSubmitted: Dispatch<SetStateAction<boolean>>,
		setShowCheckmark: Dispatch<SetStateAction<boolean>>,
	) {
		setIsSubmitted(true)
		if (isValidEmail) {
			setShowEmailInput(false)
			const mergeField = generateMergeFields(false)

			try {
				const response = await addMobileContact.mutateAsync({
					email: email,
					utmParams: mergeField,
				})

				if (response.isSuccess) {
					setShowCheckmark(true)
				} else {
					setShowEmailInput(true)
					toast.error("Failed to add mobile contact.")
				}
			} catch (error) {
				const errorDetails = formatErrorDetails(error)
				sentryCaptureException("Failed to add mobile contact", errorDetails)
				setShowEmailInput(true)
				toast.error("An error occurred while submitting your email.")
			}
		} else {
			setShowEmailInput(true)
			toast.error("Please enter a valid email address.")
		}
	}

	return { handleEmailSubmit }
}

export function generateMergeFields(mailchimp: boolean) {
	const mergeField: Record<string, string> = {}
	const cookies = {
		gclid: getCookie("gclid"),
		msclkid: getCookie("msclkid"),
		fbclid: getCookie("fbclid"),
		twclid: getCookie("twclid"),
		utm_source: getCookie("utm_source"),
		utm_medium: getCookie("utm_medium"),
		utm_campaign: getCookie("utm_campaign"),
		utm_term: getCookie("utm_term"),
		utm_content: getCookie("utm_content"),
		hutk: getCookie("hubspotutk"),
	}

	const mailchimpParameters = {
		GCLID_1: cookies.gclid,
		MSCLKID: cookies.msclkid,
		FBCLID: cookies.fbclid,
		TWCLID: cookies.twclid,
		UTM_CAMP: cookies.utm_campaign,
		UTM_SRC: cookies.utm_source,
		UTM_MED: cookies.utm_medium,
		UTM_TERM: cookies.utm_term,
		UTM_CONT: cookies.utm_content,
	}

	const defaultParameters = {
		gclid: cookies.gclid,
		msclkid: cookies.msclkid,
		fbclid: cookies.fbclid,
		twclid: cookies.twclid,
		utm_campaign: cookies.utm_campaign,
		utm_source: cookies.utm_source,
		utm_medium: cookies.utm_medium,
		utm_term: cookies.utm_term,
		utm_content: cookies.utm_content,
		hutk: cookies.hutk,
	}

	const parameters = mailchimp ? mailchimpParameters : defaultParameters

	for (const [key, value] of Object.entries(parameters)) {
		if (value !== null) {
			mergeField[key] = value
		}
	}

	return mergeField
}
