import { Input } from "@/components/ui/input.js"
import { Switch } from "@/components/ui/switch"
import type { PlatformChannel } from "@/typings/githubActionsHandler.ts"
import React from "react"
import { FaInfoCircle } from "react-icons/fa"
import teamsLogo from "../../../../assets/teams_logo.png"

interface TeamsIntegrationCardProps {
	readonly channel: PlatformChannel["id"]
	readonly onChangeChannel: (channel: PlatformChannel["id"]) => void
	readonly isSelected: boolean
	readonly onSelectChange: (e: boolean) => void
}

const TeamsIntegrationCard: React.FC<TeamsIntegrationCardProps> = ({
	channel,
	onChangeChannel,
	isSelected,
	onSelectChange,
}) => {
	const [showingMaskedValue, setShowingMaskedValue] = React.useState(true)
	const maskedValue = channel ? "••••••••••••••••" : ""

	return (
		<div className="rounded-lg border bg-white p-6">
			<div className="flex items-center justify-between gap-2">
				<div className="flex items-center">
					<div className="flex h-16 flex-shrink-0 items-center justify-center rounded-lg border p-1.5">
						<img width={50} src={teamsLogo} alt="Teams logo" />
					</div>
					<div className="ml-5">
						<p className="font-weight-600 text-base text-crb-text-primary">
							Microsoft Teams
						</p>
					</div>
				</div>
				<div className="h-fit">
					<Switch checked={isSelected} onCheckedChange={onSelectChange} />
				</div>
			</div>
			<p className="mt-4 text-sm text-crb-text-tertiary">
				{isSelected
					? "Configure the Teams webhook to send the report to."
					: "Enable and configure the Teams webhook to send the report to."}
			</p>
			{isSelected && (
				<>
					<div className="font-500 mb-1.5 mt-6 font-poppins text-sm">
						Webhook URL
					</div>
					<Input
						type="password"
						value={showingMaskedValue ? maskedValue : channel}
						onChange={e => {
							const value = e.target.value.trim()
							onChangeChannel(value)
						}}
						onFocus={() => {
							setShowingMaskedValue(false)
						}}
						onBlur={() => {
							setShowingMaskedValue(true)
						}}
						placeholder="Enter your Teams Webhook URL"
						autoComplete="off"
						spellCheck="false"
					/>
					<div className="mt-2 flex gap-1 text-xs text-gray-500">
						<FaInfoCircle className="mt-0.5 flex-shrink-0 text-gray-400" />
						Ensure your webhook URL is correct to receive reports.
						<a
							href="https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet"
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-500"
						>
							See this guide for more details on setting up MS Teams Webhooks.
						</a>
					</div>
				</>
			)}
		</div>
	)
}

export default TeamsIntegrationCard
