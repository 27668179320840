import React from "react"
import LogoFull from "../svg/logo-full"

const NotFound: React.FC = () => {
	return (
		<div className="flex h-screen w-full flex-col items-center justify-center">
			<LogoFull className="mb-2" width={400} />

			<h1 className="font-600 font-montserrat mb-2 text-4xl text-black">
				Whoops!
			</h1>
			<h2 className="font-poppins text-xl text-black">
				{" "}
				Can't find the page you're looking for.
			</h2>
		</div>
	)
}

export default NotFound
