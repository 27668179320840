import LogoFull from "@/svg/logo-full"
import type React from "react"

export const VSCodeExtensionAuth: React.FC = () => {
	return (
		<div className="grid h-screen w-full place-items-center">
			<div className="flex flex-col items-center">
				<LogoFull className="h-16" />

				<div className="mt-4 text-lg text-muted-foreground">
					Authentication successful. You can close this window.
				</div>
			</div>
		</div>
	)
}
