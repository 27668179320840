import type { OrganizationData } from "@/typings"
import type {
	CRHandlerGetReposSettingsResp,
	CRHandlerOrgLevelSettingsResp,
} from "@/typings/coderabbitHandler"
import { formatErrorDetails } from "@/utils/error-logs"
import { getSelectedOrg, sentryCaptureException } from "@/utils/utils"
import type { ConfigSettings } from "@coderabbitai/schemas"
import { SchemaV2 } from "@coderabbitai/schemas"
import type { AxiosError } from "axios"
import axios from "axios"
import { noop } from "lodash"
import type {
	Dispatch,
	FC,
	MutableRefObject,
	PropsWithChildren,
	SetStateAction,
} from "react"
import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react"
import { useForm, type UseFormReturn } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import type { CustomWidgetsProps } from "../schemaForm/types"

type SettingsConfig = ConfigSettings

interface APICallState {
	isLoading: boolean
	isError: boolean
	error?: AxiosError
}
interface ConfigCtx extends CustomWidgetsProps, APICallState {
	checkIfRepoSettings: () => boolean
	config: SettingsConfig
	initialConfig: MutableRefObject<SettingsConfig | null>
	setConfig: Dispatch<SetStateAction<SettingsConfig>>
	setLoader: Dispatch<SetStateAction<boolean>>
	setError: Dispatch<SetStateAction<boolean>>
	setRepoSettingsEnabled: Dispatch<SetStateAction<boolean>>
	setDoesOrgSettingsExist: Dispatch<SetStateAction<boolean>>
	setIsDefaultSettings: Dispatch<SetStateAction<boolean>>
	getDefaultValues: () => void
	selectedOrg: OrganizationData | null
	repo: string | null
	id?: string
	doesOrgSettingsExist: boolean
	form: UseFormReturn<{
		enabled: boolean
		dataOptOut: boolean
	}>
}

const defaultSettings = SchemaV2.parse({})

const defaultConfigCtx: ConfigCtx = {
	isRepoSettings: false,
	repoSettingsEnabled: false,
	isDefaultSettings: false,
	checkIfRepoSettings: () => false,
	config: defaultSettings,
	initialConfig: { current: null },
	setConfig: noop,
	isLoading: false,
	isError: false,
	getDefaultValues: noop,
	setRepoSettingsEnabled: noop,
	setDoesOrgSettingsExist: noop,
	setIsDefaultSettings: noop,
	selectedOrg: null,
	setError: noop,
	setLoader: noop,
	repo: null,
	doesOrgSettingsExist: false,
	form: {} as ConfigCtx["form"],
}

export const ConfigContext = createContext<ConfigCtx>(defaultConfigCtx)

export const useConfigCtx = () => useContext(ConfigContext)

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
	const [config, setConfig] = useState<ConfigCtx["config"]>(defaultSettings)

	const initialConfig = useRef<SettingsConfig | null>(null)

	const [isLoading, setLoader] = useState<boolean>(false)
	const [isError, setError] = useState<boolean>(false)

	const { id } = useParams()
	const isRepoSettings = useMemo(() => !!id, [id])
	const repo = useMemo(() => {
		const queryParams = new URLSearchParams(location.search)
		return queryParams.get("repo")
	}, [])
	const selectedOrg = getSelectedOrg()

	const navigate = useNavigate()

	const [isDefaultSettings, setIsDefaultSettings] = useState<boolean>(false)
	const [doesOrgSettingsExist, setDoesOrgSettingsExist] =
		useState<boolean>(false)
	const [repoSettingsEnabled, setRepoSettingsEnabled] = useState<boolean>(false)

	const checkIfRepoSettings = useCallback(
		() => (isRepoSettings ? !repoSettingsEnabled && !isDefaultSettings : false),
		[isDefaultSettings, isRepoSettings, repoSettingsEnabled],
	)

	const form = useForm<{
		enabled: boolean
		dataOptOut: boolean
	}>({
		defaultValues: {
			enabled: false,
			dataOptOut: false,
		},
	})

	const getDefaultValues = () => {
		const authToken = sessionStorage.getItem("accessToken")

		if (!authToken) {
			sessionStorage.clear()
			navigate("/login")
			return
		}

		setLoader(true)

		const headers = {
			Authorization: `Bearer ${authToken}`,
		}

		const getReposSettings = async () => {
			return axios.get<CRHandlerGetReposSettingsResp>(
				`${
					import.meta.env.VITE_CODERABBIT_FUNC_URL
				}/getReposSettings?repo_id=${id}&org_id=${selectedOrg?.id}`,
				{ headers },
			)
		}

		const getOrgSettings = async () => {
			return axios.get<CRHandlerOrgLevelSettingsResp>(
				`${
					import.meta.env.VITE_CODERABBIT_FUNC_URL
				}/orgLevelSettings?organization_id=${selectedOrg?.id}`,
				{ headers },
			)
		}

		;(isRepoSettings ? getReposSettings() : getOrgSettings())
			.then(async res => {
				const settings = res.data.data.settings
				const settingsData = res.data.data
				setIsDefaultSettings(res.data.default)

				if ("enabled" in settingsData) {
					setRepoSettingsEnabled(!!settingsData.enabled)
				}

				setConfig(settings)

				if (!initialConfig.current) {
					initialConfig.current = settings
				}

				form.setValue(
					"enabled",
					isRepoSettings
						? !(
								settingsData as unknown as CRHandlerGetReposSettingsResp["data"]
							).enabled
						: false,
				)

				// data_opt_out is only available in org settings
				form.setValue(
					"dataOptOut",
					!(settingsData as unknown as CRHandlerOrgLevelSettingsResp["data"])
						.data_opt_out,
				)

				if (isRepoSettings) {
					//check if org settings exist
					await getOrgSettings()
						.then(res => {
							if (res.data.orgExist) {
								setDoesOrgSettingsExist(true)
							}
						})
						.catch(err => {
							console.error(err)
						})
				}
			})
			.catch(err => {
				console.error(err)

				setError(true)
				setLoader(false)

				if (isRepoSettings) {
					const errorDetails = formatErrorDetails(err)
					sentryCaptureException("getReposSettings: API failed: ", errorDetails)
				} else {
					const errorDetails = formatErrorDetails(err)
					sentryCaptureException("orgLevelSettings: API failed: ", errorDetails)
				}
			})
			.finally(() => {
				setLoader(false)
			})
	}

	return (
		<ConfigContext.Provider
			value={{
				isRepoSettings,
				repoSettingsEnabled,
				isDefaultSettings,
				checkIfRepoSettings,
				config,
				setConfig,
				isLoading,
				isError,
				getDefaultValues,
				selectedOrg,
				setError,
				setLoader,
				repo,
				id: id ?? "",
				doesOrgSettingsExist,
				setRepoSettingsEnabled,
				setDoesOrgSettingsExist,
				setIsDefaultSettings,
				form,
				initialConfig,
			}}
		>
			{children}
		</ConfigContext.Provider>
	)
}
