import React from "react"

interface ReportingNameProps {
	name: string
	setName: React.Dispatch<React.SetStateAction<string>>
	disabled?: boolean
}

const ReportingName: React.FC<ReportingNameProps> = ({
	name,
	setName,
	disabled,
}) => {
	return (
		<div>
			<div className="font-500 font-poppins">Name</div>
			<input
				value={name}
				onChange={event => {
					setName(event.target.value)
				}}
				placeholder="Enter the name here"
				className="mt-2 w-full rounded-lg border border-gray-300 p-2 focus:border-transparent
					focus:outline-none focus:ring-2 focus:ring-black md:w-1/2"
				disabled={disabled}
			/>
		</div>
	)
}

export default ReportingName
