import { Button } from "@/components/ui/button"
import React from "react"
import { LuBadgeDollarSign } from "react-icons/lu"
import { MdCheckCircle } from "react-icons/md"
import { useNavigate } from "react-router"
import imgSrc from "../assets/upgrade-plan-img.png"

const featuresList = [
	"Linters and SAST tools support",
	"Integrates with Jira & Linear",
	"Product analytics dashboards",
	"Customizable reports",
	"Docstrings generation",
]
const title = "Unlock by upgrading to Pro Plan"

const ProFeatureOnUpgrade: React.FC<{ feature: string }> = ({ feature }) => (
	<div className="flex items-center gap-2">
		<MdCheckCircle className="text-crb-primary-dark" />
		<p className="text-lg font-normal">{feature}</p>
	</div>
)

export const UpgradeToProPlanMsg: React.FC = () => {
	const nav = useNavigate()
	return (
		<div className="container mx-auto p-4 font-poppins">
			<div className="grid min-h-[600px] grid-cols-1 gap-2 md:grid-cols-2">
				<div className="order-2 flex flex-col justify-center gap-2 md:order-1">
					<p className="mb-5 text-3xl font-medium">{title}</p>
					{featuresList.map((feature, index) => (
						<ProFeatureOnUpgrade key={index} feature={feature} />
					))}
					<Button
						className="mt-3 w-[fit-content]"
						onClick={() => {
							nav("/settings/subscription")
						}}
					>
						<div className="flex items-center gap-3">
							<LuBadgeDollarSign size={18} />
							Upgrade Now
						</div>
					</Button>
				</div>
				<div className="order-1 flex flex-col justify-center gap-2 md:order-2">
					<img
						src={imgSrc}
						alt="Upgrade to Pro Plan"
						className="h-auto w-full max-w-lg"
					/>
				</div>
			</div>
		</div>
	)
}
