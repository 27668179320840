import { trpc } from "@/trpc"
import { formatErrorDetails } from "@/utils/error-logs"
import { sentryCaptureException } from "@/utils/utils"
import type { FormData } from "../../components/AnalyticsPopup/Popup"

export function useHubspotUpdater() {
	const updateContactSignUpCompleted =
		trpc.hubspot.updateContactSignUpCompleted.useMutation()
	const updateEmailAndContactSignUpCompleted =
		trpc.hubspot.updateContactEmailAndSignUpCompleted.useMutation()

	async function updateHubspotContact(
		formData: FormData,
		oldEmail: string,
		sameEmail: boolean,
	) {
		try {
			if (!sameEmail) {
				// Update contact's email in Hubspot
				return await updateEmailAndContactSignUpCompleted.mutateAsync({
					oldEmail,
					newEmail: formData.email.trim(),
				})
			} else {
				// Mark signup as completed
				return await updateContactSignUpCompleted.mutateAsync({
					email: formData.email.trim(),
				})
			}
		} catch (err) {
			const errorDetails = formatErrorDetails(err)
			sentryCaptureException(
				"updateHubspot: Failed to update Hubspot contact",
				errorDetails,
			)
			throw err
		}
	}

	return { updateHubspotContact }
}
