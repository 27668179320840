import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { EMAIL_REGEX, TENANT_NAME_REGEX } from "@/utils/constant"
import { getSelectedOrg } from "@/utils/utils"
import { useState, type FC } from "react"
import { useForm } from "react-hook-form"
import { IoMdAddCircleOutline } from "react-icons/io"
import { toast } from "react-toastify"

interface FormData {
	email: string
	fullName: string
	tenantName?: string
}
interface InviteUserProps {
	readonly disabled: boolean
}
export const InviteUser: FC<InviteUserProps> = ({ disabled }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>()
	const [open, setOpen] = useState(false)
	const { isLoading, mutate: invite } =
		trpc.inviteBillingAdminToOrg.invite.useMutation()

	const selectedOrg = getSelectedOrg()

	const isOrgNameAvailable =
		trpc.inviteBillingAdminToOrg.isTenantNameAvailable.useQuery(
			selectedOrg?.organization_name || "",
			{
				enabled: !!selectedOrg?.organization_name && open,
			},
		)

	const onSubmit = (data: FormData) => {
		invite(data, {
			onError: error => {
				logger.error("Error inviting user: ", error)
				toast.error(`Error inviting user: ${error.message}`)
			},
			onSuccess: res => {
				if (!res.isSuccess) {
					toast.error(res.message)
					return
				}
				toast.success(`Invite sent to ${data.email}`)
				setOpen(false)
			},
		})
	}

	return (
		<div>
			<Button
				onClick={() => {
					setOpen(true)
				}}
				disabled={disabled}
				className="bg-crb-primary"
			>
				<IoMdAddCircleOutline className="mr-2" size={20} />
				Invite Billing Admin
			</Button>

			<Dialog
				open={open}
				onOpenChange={open => {
					setOpen(open)
				}}
			>
				<DialogContent>
					<DialogTitle>Invite User</DialogTitle>
					<div className="font-poppins text-sm font-normal not-italic leading-4 text-[#606060]">
						If you want to invite an admin user who is not a part of the
						organization in your git provider (GitHub/GitLab/Azure DevOps), you
						can invite them using their email address. They can sign in on{" "}
						<a
							target="__blank"
							className="font-medium text-crb-primary hover:underline"
							href={`/sign-in-with-email/${isOrgNameAvailable.data?.isTenantNameAvailable ? isOrgNameAvailable.data.tenantName : ""}`}
						>
							this page
						</a>
						.
					</div>

					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-2">
							{!isOrgNameAvailable.isLoading &&
								selectedOrg?.organization_name &&
								!isOrgNameAvailable.data?.isTenantNameAvailable && (
									<div>
										<Input
											id="TenantName"
											type="text"
											placeholder="Tenant Name"
											{...register("tenantName", {
												required: "Tenant Name is required",
												pattern: {
													value: TENANT_NAME_REGEX,
													message:
														"This must be 4-20 characters consisting of letters, digits, and hyphens and must begin with a letter.",
												},
											})}
										/>
										{errors.tenantName ? (
											<p className="mt-1 text-sm text-red-500">
												{errors.tenantName.message}
											</p>
										) : (
											<p className="mt-1 text-sm text-crb-primary-dark">
												Your organization name cannot be used as a tenant name
												as it is already taken or is not valid. Please enter a
												different tenant name.
											</p>
										)}
									</div>
								)}
							<div>
								<Input
									id="fullName"
									type="text"
									placeholder="Full Name"
									{...register("fullName", {
										required: "Full Name is required",
									})}
								/>
								{errors.fullName && (
									<p className="mt-1 text-sm text-red-500">
										{errors.fullName.message}
									</p>
								)}
							</div>
							<div>
								<Input
									id="email"
									type="email"
									placeholder="Email"
									{...register("email", {
										required: "Email is required",
										pattern: {
											value: EMAIL_REGEX,
											message: "Invalid email address",
										},
									})}
								/>
								{errors.email && (
									<p className="mt-1 text-sm text-red-500">
										{errors.email.message}
									</p>
								)}
							</div>
						</div>
						<Button
							type="submit"
							className="mt-3 w-full text-secondary-foreground text-white"
							disabled={isLoading}
						>
							Send invite
						</Button>
					</form>
				</DialogContent>
			</Dialog>
		</div>
	)
}
