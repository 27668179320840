import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { trpc } from "@/trpc"
import { formatErrorDetails } from "@/utils/error-logs"
import { sentryCaptureException } from "@/utils/utils"
import { useState } from "react"
import { toast } from "react-toastify"

interface CircleCIModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	onSaveToken: (tokenData: {
		access_token_encrypted: string
		access_token_tag: string
		access_token_iv: string
		access_token_validity: string
	}) => void
}

export default function CircleCIModal({
	open,
	onOpenChange,
	onSaveToken,
}: CircleCIModalProps) {
	const [token, setToken] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const trpcUtils = trpc.useUtils()

	const handleSaveToken = async () => {
		try {
			setIsLoading(true)
			const response =
				await trpcUtils.client.integrations.getCircleCIAccessToken.query(token)
			if (response.isSuccess) {
				const data = response.data
				onSaveToken({
					access_token_encrypted: data.access_token_encrypted,
					access_token_tag: data.access_token_tag,
					access_token_iv: data.access_token_iv,
					access_token_validity: data.access_token_validity,
				})
			} else {
				toast.error("Failed to encrypt CircleCI token. Please try again.")
			}
		} catch (error) {
			sentryCaptureException(
				"CircleCIModal: Failed to encrypt token: ",
				formatErrorDetails(error),
			)
			toast.error("Failed to encrypt CircleCI token. Please try again.")
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Connect CircleCI</DialogTitle>
					<DialogDescription>
						<div className="space-y-2">
							<p>
								To connect CircleCI, you'll need to generate a Personal API
								Token.
							</p>
							<p>
								1. Visit your{" "}
								<a
									href="https://app.circleci.com/settings/user/tokens"
									target="_blank"
									rel="noopener noreferrer"
									className="text-primary hover:underline"
								>
									CircleCI User Settings
								</a>
							</p>
							<p>2. Click "Create New Token"</p>
							<p>3. Give it a name (e.g. "CodeRabbit Integration")</p>
							<p>4. Copy the generated token and paste it below</p>
						</div>
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					<div className="grid gap-2">
						<Label htmlFor="token">API Token</Label>
						<Input
							id="token"
							type="password"
							value={token}
							onChange={e => {
								setToken(e.target.value)
							}}
							placeholder="Enter your CircleCI API token"
						/>
					</div>
				</div>
				<DialogFooter>
					<Button
						type="submit"
						onClick={handleSaveToken}
						disabled={!token || isLoading}
					>
						{isLoading ? "Saving..." : "Save"}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
