import { Skeleton } from "@/components/ui/skeleton"
import { trpc } from "@/trpc"
import type {
	PromptTemplate,
	ScheduleGroup,
} from "@/typings/githubActionsHandler"
import { ScheduleGroups } from "@/typings/githubActionsHandler"
import { useProvider } from "@/utils/providers"
import { capitalizeFirstLetter } from "@/utils/utils"
import React, { useEffect, useMemo } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import type { reportTypes } from "../RecurringReports"
import type { PromptTemplates } from "./prompt"
import { PROMPT_MAX_LENGTH } from "./prompt"

interface ReportingPromptProps {
	promptTemplate: PromptTemplate
	setPromptTemplate: React.Dispatch<React.SetStateAction<PromptTemplate>>
	prompt: string
	setPrompt: React.Dispatch<React.SetStateAction<string>>
	group: ScheduleGroup
	setGroup: React.Dispatch<React.SetStateAction<ScheduleGroup>>
	subgroup: ScheduleGroup
	setSubgroup: React.Dispatch<React.SetStateAction<ScheduleGroup>>
	disabled?: boolean
	exampleResults: string
	setExampleResults: React.Dispatch<React.SetStateAction<string>>
	reportType: keyof typeof reportTypes
}

const ReportingPrompt: React.FC<ReportingPromptProps> = ({
	promptTemplate,
	setPromptTemplate,
	prompt,
	setPrompt,
	group,
	setGroup,
	subgroup,
	setSubgroup,
	disabled,
	exampleResults,
	setExampleResults,
	reportType,
}) => {
	const navigate = useNavigate()
	const { isGitlab } = useProvider()

	const scheduleGroups = useMemo(
		() => ScheduleGroups.filter(group => !(isGitlab && group === "TEAM")),
		[isGitlab],
	)

	const [promptTemplates, setPromptTemplates] = React.useState<PromptTemplates>(
		{},
	)

	function isPromptTemplate(
		value: string,
	): value is NonNullable<PromptTemplate> {
		return Object.keys(promptTemplates).includes(value)
	}

	const getReportingPrompts = trpc.reporting.getReportingPrompts.useQuery()

	useEffect(() => {
		if (getReportingPrompts.data) {
			setPromptTemplates(getReportingPrompts.data.data.promptTemplates)
		}
	}, [getReportingPrompts.data])

	useEffect(() => {
		if (getReportingPrompts.error) {
			if (getReportingPrompts.error.data?.httpStatus === 401) {
				// User does not have a subscription and hence cannot create/edit reports
				navigate("/reports")
				return
			}

			toast.error(
				"Failed to fetch prompt templates: " +
					getReportingPrompts.error.message,
			)
		}
	}, [getReportingPrompts.error])

	useEffect(() => {
		if (!prompt && promptTemplate && promptTemplate in promptTemplates) {
			setPrompt(promptTemplates[promptTemplate] || "")
		}
	}, [promptTemplates, promptTemplate])

	useEffect(() => {
		if (exampleResults === undefined) {
			setExampleResults("")
		}
	}, [exampleResults, setExampleResults])

	const isLoading = getReportingPrompts.isLoading

	return (
		<div>
			<div>
				<div className="font-500 mt-2 font-poppins">Prompt</div>
				<div className="max-w-lg font-poppins text-sm text-muted-foreground">
					Adjust the AI prompt to your liking for personalizing the report.
				</div>
			</div>

			<div className="my-4">
				<div>
					<div className="font-500 font-poppins text-sm">Prompt template</div>
					{isLoading && <Skeleton className="mt-1 h-8 w-1/2" />}
					{!isLoading && (
						<select
							className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
							value={promptTemplate}
							onChange={event => {
								const value = event.target.value
								if (isPromptTemplate(value)) {
									setPromptTemplate(value)
									const promptTemplateText = promptTemplates[value]
									if (promptTemplateText != null) {
										setPrompt(promptTemplateText)
									}
								}
							}}
							disabled={disabled}
						>
							{Object.keys(promptTemplates).map(prompt => (
								<option key={prompt} value={prompt}>
									{prompt}
								</option>
							))}
						</select>
					)}
				</div>
				<div className="mt-5">
					<div className="font-500 font-poppins text-sm">Prompt</div>
					{isLoading && <Skeleton className="mt-1 h-32 w-full" />}
					{!isLoading && (
						<textarea
							disabled={disabled}
							className="mt-1 w-full rounded-lg border border-gray-300 p-2"
							rows={7}
							value={prompt}
							onChange={event => {
								if (promptTemplates["Custom"] != null) {
									setPromptTemplate("Custom")
								}
								setPrompt(event.target.value)
							}}
							maxLength={PROMPT_MAX_LENGTH}
						/>
					)}
					{!isLoading && (
						<div className="mx-1 flex gap-1 text-xs text-gray-500">
							<FaInfoCircle className="mt-0.5 flex-shrink-0 text-gray-400" />
							{
								"{{today}} in the prompt will be replaced by the date the report is generated at"
							}
						</div>
					)}
				</div>
				{/*
				disable this for now
				remove 'exampleResults === "foobar" &&' if we choose to enable this
				*/}
				{reportType !== "ondemand" && exampleResults === "foobar" && (
					<div className="mt-5">
						<div className="font-500 font-poppins text-sm">Example Results</div>
						{isLoading && <Skeleton className="mt-1 h-32 w-full" />}
						{!isLoading && (
							<>
								<textarea
									disabled={disabled}
									className="mt-1 w-full rounded-lg border border-gray-300 p-2"
									rows={7}
									value={exampleResults}
									onChange={event => {
										setExampleResults(event.target.value)
									}}
									maxLength={PROMPT_MAX_LENGTH}
								/>
								<div className="mx-1 flex gap-1 text-xs text-gray-500">
									<FaInfoCircle className="mt-0.5 flex-shrink-0 text-gray-400" />
									<span>
										Example results help you understand what the report will
										look like.
										<br />
										They will appear after you first run the report. You can
										edit the example results to standardize the output format.
										<br />
										Erase the prompt and save the report to clear the example.
										Then a new example will be generated the next time the
										report is run.
									</span>
								</div>
							</>
						)}
					</div>
				)}
				<div className="mt-5">
					<div className="font-500 font-poppins text-sm">Group by</div>
					<select
						className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
						value={group}
						onChange={event => {
							setGroup(event.target.value as typeof group)
						}}
					>
						{scheduleGroups.map(group => (
							<option key={group} value={group}>
								{capitalizeFirstLetter(group.toLowerCase())}
							</option>
						))}
					</select>
				</div>
				{group !== "NONE" && (
					<div className="mt-5">
						<div className="font-500 font-poppins text-sm">Subgroup</div>
						<select
							className="mt-1 w-full rounded-lg border border-gray-300 bg-white p-2 sm:w-1/2"
							value={subgroup}
							onChange={event => {
								setSubgroup(event.target.value as typeof subgroup)
							}}
						>
							{scheduleGroups
								.filter(g => g !== group)
								.map(group => (
									<option key={group} value={group}>
										{capitalizeFirstLetter(group.toLowerCase())}
									</option>
								))}
						</select>
					</div>
				)}
			</div>
		</div>
	)
}

export default ReportingPrompt
