import { useSelectedOrg } from "@/components/Nav/useSelectedOrg"
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch.js"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { trpc, type RouterInputs } from "@/trpc"
import type {
	GHHandlerGetJiraRefreshTokenResp,
	GHHandlerGetLinearRefreshTokenResp,
} from "@/typings/githubActionsHandler.ts"
import { formatErrorDetails } from "@/utils/error-logs"
import { getOrgRole, isOrgAdmin } from "@/utils/orgUtils"
import { handleSessionExpiration } from "@/utils/session"
import {
	getSelectedOrg,
	isTRPCClientError,
	sentryCaptureException,
} from "@/utils/utils.ts"
import { useEffect, useState } from "react"
import { CgSpinner } from "react-icons/cg"
import { FaExclamationTriangle } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import circleci from "../../assets/circleci.png"
import jira from "../../assets/jira.svg"
import linear from "../../assets/linear.svg"
import Loader from "../../components/Loader/Loader"
import CircleCIModal from "./CircleCIModal"
import JiraSelfHostedModal from "./JiraSelfHostedModal"

export default function Integrations() {
	const [isJiraAuthorized, setIsJiraAuthorized] = useState<boolean>(false)
	const [isJiraIntegrationSaved, setIsJiraIntegrationSaved] =
		useState<boolean>(false)
	const [jiraSelfHostedURL, setJiraSelfHostedURL] = useState<string>()
	const [isJiraInvalidAuth, setIsJiraInvalidAuth] = useState<boolean>(false)
	const [isLinearAuthorized, setIsLinearAuthorized] = useState<boolean>(false)
	const [isLinearIntegrationSaved, setIsLinearIntegrationSaved] =
		useState<boolean>(false)
	const [isLinearInvalidAuth, setIsLinearInvalidAuth] = useState<boolean>(false)
	const [isCircleCIAuthorized, setIsCircleCIAuthorized] =
		useState<boolean>(false)
	const [isCircleCIIntegrationSaved, setIsCircleCIIntegrationSaved] =
		useState<boolean>(false)
	const [isCircleCIInvalidAuth, setIsCircleCIInvalidAuth] =
		useState<boolean>(false)

	const JIRA = "jira"
	const LINEAR = "linear"
	const CIRCLECI = "circleci"

	const navigate = useNavigate()
	const trpcUtils = trpc.useUtils()
	const selectedOrg = useSelectedOrg()

	const isAdmin = isOrgAdmin(selectedOrg)
	const role = getOrgRole(selectedOrg)

	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")

	const [openSelfHostedModal, setOpenSelfHostedModal] = useState<boolean>(false)
	const [openCircleCIModal, setOpenCircleCIModal] = useState<boolean>(false)

	// Check session and fetch integrations
	useEffect(() => {
		const accessToken = sessionStorage.getItem("accessToken")
		if (!accessToken || !selectedOrg) {
			navigate("/learnings")
			return
		}

		function fetchData() {
			setLoader(true)
			const urlParams = new URLSearchParams(window.location.search)
			const urlState = urlParams.get("state")
			const service = sessionStorage.getItem("service")
			if (service && urlState) {
				setLoader(true)
				const authCode = urlParams.get("code")
				const storedState = sessionStorage.getItem("state")
				if (storedState != urlState) {
					console.error("CSRF attack detected! The states do not match.")
					return
				}
				if (authCode) {
					const setIsAuthorized =
						service === "jira"
							? setIsJiraAuthorized
							: service === "linear"
								? setIsLinearAuthorized
								: setIsCircleCIAuthorized
					const setIsIntegrationSaved =
						service === "jira"
							? setIsJiraIntegrationSaved
							: service === "linear"
								? setIsLinearIntegrationSaved
								: setIsCircleCIIntegrationSaved

					void handleAuthorization(
						authCode,
						setIsAuthorized,
						setIsIntegrationSaved,
						service,
					)
				}
			}
			void fetchIntegrations()
		}
		fetchData()
	}, [navigate, selectedOrg])

	async function fetchIntegrations() {
		try {
			setLoaderMessage("Fetching Integrations... 🚀")
			if (!getSelectedOrg()?.id) {
				throw new Error("Organization ID not found.")
			}
			const response =
				await trpcUtils.client.integrations.getIntegrations.query()

			if (response.isSuccess) {
				const integrations = response.data
				if (integrations.length > 0) {
					integrations.forEach(integration => {
						if (integration.service === JIRA.toUpperCase()) {
							setIsJiraAuthorized(true)
							setIsJiraIntegrationSaved(true)
							if (integration.isSelfHosted && integration.host_url) {
								setJiraSelfHostedURL(integration.host_url)
							}
							setIsJiraInvalidAuth(integration.isRefreshTokenInvalid)
						} else if (integration.service === LINEAR.toUpperCase()) {
							setIsLinearAuthorized(true)
							setIsLinearIntegrationSaved(true)
							setIsLinearInvalidAuth(integration.isRefreshTokenInvalid)
						} else if (integration.service === CIRCLECI.toUpperCase()) {
							setIsCircleCIAuthorized(true)
							setIsCircleCIIntegrationSaved(true)
							setIsCircleCIInvalidAuth(integration.isRefreshTokenInvalid)
						}
					})
				}
				setLoader(false)
			}
		} catch (error) {
			if (isTRPCClientError(error)) {
				if (error.data?.code === "UNAUTHORIZED") {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.code === "BAD_REQUEST") {
					toast.error(
						"Failed to fetch integrations. Please try logging back in.",
					)
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException(
						"Integrations: fetchIntegrations API failed: ",
						errorDetails,
					)
					setLoader(false)
					return
				}
			}
			setLoader(false)
			if (error instanceof Error) {
				if (error.message === "Organization ID not found.") {
					toast.error(
						"Failed to fetch integrations. Please try logging out and back in.",
					)
					return
				}
			}
			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				"Integrations: Organization ID not found. ",
				errorDetails,
			)
		}
	}

	/**
	 * Saves the integration data.
	 * @param data - The integration data.
	 * @param setIsAuthorized - The function to set authorization status.
	 * @param setIsIntegrationSaved - The function to set integration saved status.
	 */
	async function saveIntegration(
		data:
			| GHHandlerGetJiraRefreshTokenResp
			| GHHandlerGetLinearRefreshTokenResp
			| RouterInputs["integrations"]["saveIntegration"],
		setIsAuthorized: typeof setIsJiraAuthorized,
		setIsIntegrationSaved: typeof setIsJiraIntegrationSaved,
	) {
		setLoader(true)
		setLoaderMessage("Saving Integration... 🚀")

		try {
			const saveIntegrationData: RouterInputs["integrations"]["saveIntegration"] =
				"pat" in data
					? /* Jira Self-Hosted/CircleCI Cloud  */
						{
							service: data.service === "Jira" ? "JIRA" : "CIRCLECI",
							access_token_encrypted: data.pat,
							access_token_tag: "",
							access_token_iv: "",
							access_token_validity: new Date(
								Date.now() + 5 * 365 * 24 * 60 * 60 * 1000,
							).toISOString(), // 5 years
							host_url: "host_url" in data ? data.host_url : undefined,
						}
					: /* Jira Cloud/Linear Cloud */
						{
							service: data.service,
							service_id: data.service_id || "",
							host_url: data.host_url || "",
							access_token_encrypted: data.access_token_encrypted,
							access_token_tag: data.access_token_tag,
							access_token_iv: data.access_token_iv,
							access_token_validity: data.access_token_validity,
							refresh_token_encrypted: data.refresh_token_encrypted || "",
							refresh_token_tag: data.refresh_token_tag || "",
							refresh_token_iv: data.refresh_token_iv || "",
						}

			await trpcUtils.client.integrations.saveIntegration
				.mutate(saveIntegrationData)
				.then(() => {
					setIsAuthorized(true)
					setIsIntegrationSaved(true)
					if ("pat" in data && data.service === "Jira") {
						setJiraSelfHostedURL(data.host_url)
					}
					setIsJiraInvalidAuth(false)
					setIsLinearInvalidAuth(false)
					setIsCircleCIInvalidAuth(false)
					setOpenSelfHostedModal(false)
					setOpenCircleCIModal(false)
					sessionStorage.removeItem("state")
					sessionStorage.removeItem("service")
				})
				.catch(error => {
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException(
						"Integrations: saveIntegration API failed: ",
						errorDetails,
					)
					toast.error("Failed to save integration: " + error)
				})
				.finally(() => {
					setLoader(false)
				})
		} catch (error) {
			if (isTRPCClientError(error)) {
				if (error.data?.code === "FORBIDDEN") {
					const provider = selectedOrg?.type === "gitlab" ? "GitLab" : "GitHub"
					toast.error(
						`This operation requires admin privileges in ${provider}. Your current ${provider} role is ${role}.`,
					)
					return
				}
			}
			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				"Integrations: saveIntegration API failed: ",
				errorDetails,
			)
			toast.error("Failed to save integration: " + error)
			setLoader(false)
		}
	}

	async function handleAuthorization(
		authCode: string,
		setIsAuthorized: typeof setIsJiraAuthorized,
		setIsIntegrationSaved: typeof setIsJiraIntegrationSaved,
		service: string,
	) {
		if (service === JIRA) {
			await trpcUtils.client.integrations.getJiraRefreshToken
				.query(authCode)
				.then(async response => {
					if (response.isSuccess) {
						const data = response.data

						await saveIntegration(data, setIsAuthorized, setIsIntegrationSaved)
						const newUrl = `${window.location.origin}/integrations`
						window.history.replaceState(null, "", newUrl)
					} else {
						toast.error(
							"Failed to connect Jira integration. Please try again later.",
						)
					}
				})
				.catch(error => {
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException(
						"Integrations: JiraRefreshToken API failed: ",
						errorDetails,
					)
					toast.error(
						"Failed to connect Jira integration. Please try again later.",
					)
					if (error.response?.status === 401) {
						handleSessionExpiration(navigate)
					}
				})
		} else if (service === LINEAR) {
			await trpcUtils.client.integrations.getLinearAccessToken
				.query(authCode)
				.then(async response => {
					if (response.isSuccess) {
						const data = response.data

						await saveIntegration(data, setIsAuthorized, setIsIntegrationSaved)
						const newUrl = `${window.location.origin}/integrations`
						window.history.replaceState(null, "", newUrl)
					} else {
						toast.error(
							"Failed to connect Linear integration. Please try again later.",
						)
					}
				})
				.catch(error => {
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException(
						"Integrations: LinearAccessToken API failed: ",
						errorDetails,
					)
					toast.error(
						"Failed to connect Linear integration. Please try again later.",
					)
				})
		} else if (service === CIRCLECI) {
			await trpcUtils.client.integrations.getCircleCIAccessToken
				.query(authCode)
				.then(async response => {
					if (response.isSuccess) {
						const data = response.data

						await saveIntegration(
							{
								service: "CIRCLECI",
								access_token_encrypted: data.access_token_encrypted,
								access_token_tag: data.access_token_tag,
								access_token_iv: data.access_token_iv,
								access_token_validity: data.access_token_validity,
							},
							setIsCircleCIAuthorized,
							setIsCircleCIIntegrationSaved,
						)
						const newUrl = `${window.location.origin}/integrations`
						window.history.replaceState(null, "", newUrl)
					} else {
						toast.error(
							"Failed to connect CircleCI integration. Please try again later.",
						)
					}
				})
				.catch(error => {
					sentryCaptureException(
						"Integrations: CircleCIAccessToken API failed: ",
						formatErrorDetails(error),
					)
					toast.error(
						"Failed to connect CircleCI integration. Please try again later.",
					)
				})
		}
	}

	// Jira
	const handleJiraConnect = () => {
		try {
			const JIRA_AUTH_URL = import.meta.env.VITE_JIRA_AUTH_URL
			const JIRA_CLIENT_ID = import.meta.env.VITE_JIRA_CLIENT_ID
			const JIRA_REDIRECT_URI = import.meta.env.VITE_JIRA_REDIRECT_URI

			if (!JIRA_AUTH_URL || !JIRA_CLIENT_ID || !JIRA_REDIRECT_URI) {
				toast.error(
					"Missing Jira configuration. Please check environment variables.",
				)
				return
			}

			const jiraAuthUrl = new URL(JIRA_AUTH_URL)

			// Validate URL protocol for security
			if (jiraAuthUrl.protocol !== "https:") {
				toast.error("Invalid Jira auth URL protocol: must use https")
				return
			}

			const state = generateNonce(10)
			sessionStorage.setItem("state", state)
			sessionStorage.setItem("service", JIRA)

			const appendUrlParams = (params: Record<string, string>) => {
				Object.entries(params).forEach(([key, value]) => {
					jiraAuthUrl.searchParams.append(key, value)
				})
			}
			appendUrlParams({
				audience: "api.atlassian.com",
				client_id: JIRA_CLIENT_ID,
				scope:
					"read:jira-work manage:jira-webhook write:jira-work offline_access",
				redirect_uri: JIRA_REDIRECT_URI,
				state: state,
				response_type: "code",
				prompt: "consent",
			})

			window.location.href = jiraAuthUrl.toString()
		} catch (error) {
			console.error("Error initializing Jira connection")
			toast.error("Failed to connect to Jira. Please try again.")
		}
	}

	async function handleJiraDisconnect() {
		try {
			const ownerId = sessionStorage.getItem("org_id")
			if (!ownerId) {
				toast.error(
					"An Error occurred removing the Jira integration, please try again later.",
				)
				return
			}
			await trpcUtils.client.integrations.disconnectIntegration
				.mutate({
					service: JIRA,
				})
				.then(response => {
					if (response.isSuccess) {
						setIsJiraAuthorized(false)
						setIsJiraIntegrationSaved(false)
						setJiraSelfHostedURL(undefined)
						setIsJiraInvalidAuth(false)
						toast.success("Jira integration disconnected successfully.")
					} else {
						toast.error(response.message)
					}
				})
		} catch (error) {
			if (isTRPCClientError(error)) {
				if (error.data?.code === "FORBIDDEN") {
					const provider = selectedOrg?.type === "gitlab" ? "GitLab" : "GitHub"
					toast.error(
						`This operation requires admin privileges in ${provider}. Your current ${provider} role is ${role}.`,
					)
					return
				}
			}
			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				"Integrations: disconnectJiraIntegration API failed: ",
				errorDetails,
			)
			toast.error(
				"An Error occurred removing the Jira integration, please try again later.",
			)
		}
	}

	// Linear
	const handleLinearConnect = () => {
		try {
			const LINEAR_AUTH_URL = import.meta.env.VITE_LINEAR_AUTH_URL
			const LINEAR_CLIENT_ID = import.meta.env.VITE_LINEAR_CLIENT_ID
			const LINEAR_REDIRECT_URI = import.meta.env.VITE_LINEAR_REDIRECT_URI

			if (!LINEAR_AUTH_URL || !LINEAR_CLIENT_ID || !LINEAR_REDIRECT_URI) {
				toast.error(
					"Missing Linear configuration. Please check environment variables.",
				)
				return
			}

			const linearAuthUrl = new URL(LINEAR_AUTH_URL)
			const state = generateNonce(10)
			sessionStorage.setItem("state", state)
			sessionStorage.setItem("service", LINEAR)

			const appendUrlParams = (params: Record<string, string>) => {
				Object.entries(params).forEach(([key, value]) => {
					linearAuthUrl.searchParams.append(key, value)
				})
			}

			appendUrlParams({
				client_id: LINEAR_CLIENT_ID,
				redirect_uri: LINEAR_REDIRECT_URI,
				response_type: "code",
				scope: "read,write",
				state: state,
				prompt: "consent",
				actor: "application",
			})

			window.location.href = linearAuthUrl.toString()
		} catch (error) {
			console.error("Error in handleLinearConnect:", error)
			toast.error("Failed to connect to Linear. Please try again.")
		}
	}

	async function handleLinearDisconnect() {
		try {
			const ownerId = sessionStorage.getItem("org_id")
			if (!ownerId) {
				toast.error(
					"An Error occurred removing the linear integration, please try again later.",
				)
				return
			}
			await trpcUtils.client.integrations.disconnectIntegration
				.mutate({
					service: LINEAR,
				})
				.then(res => {
					if (res.isSuccess) {
						setIsLinearAuthorized(false)
						setIsLinearIntegrationSaved(false)
						setIsLinearInvalidAuth(false)
						toast.success("Linear integration disconnected successfully.")
					} else {
						toast.error(res.message)
					}
				})
				.catch(error => {
					toast.error(
						"An Error occurred removing the linear integration, please try again later.",
					)
					const errorDetails = formatErrorDetails(error)
					sentryCaptureException(
						"Integrations: disconnectLinearIntegration API failed: ",
						errorDetails,
					)
				})
		} catch (error) {
			const errorDetails = formatErrorDetails(error)
			sentryCaptureException(
				"Integrations: disconnectLinearIntegration API failed: ",
				errorDetails,
			)
		}
	}

	// CircleCI
	const handleCircleCIConnect = () => {
		setOpenCircleCIModal(true)
	}

	async function handleCircleCIDisconnect() {
		try {
			const ownerId = sessionStorage.getItem("org_id")
			if (!ownerId) {
				toast.error(
					"An Error occurred removing the CircleCI integration, please try again later.",
				)
				return
			}
			await trpcUtils.client.integrations.disconnectIntegration
				.mutate({
					service: CIRCLECI,
				})
				.then(res => {
					if (res.isSuccess) {
						setIsCircleCIAuthorized(false)
						setIsCircleCIIntegrationSaved(false)
						setIsCircleCIInvalidAuth(false)
						toast.success("CircleCI integration disconnected successfully.")
					} else {
						toast.error(res.message)
					}
				})
		} catch (error) {
			sentryCaptureException(
				"Integrations: disconnectCircleCIIntegration API failed: ",
				formatErrorDetails(error),
			)
			toast.error(
				"An Error occurred removing the CircleCI integration, please try again later.",
			)
		}
	}

	function generateNonce(length: number): string {
		const characters =
			"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
		const randomBytes = new Uint8Array(length)
		window.crypto.getRandomValues(randomBytes)

		let result = ""
		for (const byte of randomBytes) {
			const randomIndex = byte % characters.length
			result += characters.charAt(randomIndex)
		}

		return result
	}

	const integrations = [
		{
			name: "Jira",
			description: "Plan, track, and release great software.",
			logo: jira,
			authorized: isJiraAuthorized,
			integrationSaved: isJiraIntegrationSaved,
			showLoader: isJiraAuthorized && !isJiraIntegrationSaved,
			connect: handleJiraConnect,
			disconnect: handleJiraDisconnect,
			invalidAuth: isJiraInvalidAuth,
		},
		{
			name: "Linear",
			description: "Streamline software projects, sprints, and bug tracking.",
			logo: linear,
			authorized: isLinearAuthorized,
			integrationSaved: isLinearIntegrationSaved,
			showLoader: isLinearAuthorized && !isLinearIntegrationSaved,
			connect: handleLinearConnect,
			disconnect: handleLinearDisconnect,
			invalidAuth: isLinearInvalidAuth,
		},
		{
			name: "CircleCI",
			description:
				"Continuous Integration and Delivery Platform. Connect your CircleCI builds for enhanced PR analysis.",
			logo: circleci,
			authorized: isCircleCIAuthorized,
			integrationSaved: isCircleCIIntegrationSaved,
			showLoader: isCircleCIAuthorized && !isCircleCIIntegrationSaved,
			connect: handleCircleCIConnect,
			disconnect: handleCircleCIDisconnect,
			invalidAuth: isCircleCIInvalidAuth,
		},
	] as const

	const handleToggleIntegration = async (
		integration: (typeof integrations)[number],
		checked: boolean,
	) => {
		if (!selectedOrg) {
			toast.error("Please log in to connect integrations.")
			return
		}
		try {
			if (checked) {
				console.log(`Attempting to connect ${integration.name}`)
				integration.connect()
			} else if (isAdmin) {
				console.log(`Attempting to disconnect ${integration.name}`)
				await integration.disconnect()
			} else {
				toast.error(
					`You need admin privileges to disconnect ${integration.name}.`,
				)
				return
			}
		} catch (error) {
			const errorMessage =
				error instanceof Error ? error.message : "Unknown error occurred"
			console.error(`Error with ${integration.name} integration:`, errorMessage)
			toast.error(
				`Failed to ${checked ? "connect to" : "disconnect from"} ${integration.name}: ${errorMessage}`,
			)
		}
	}

	return (
		<>
			{loader ? (
				<Loader size="small" message={loaderMessage} />
			) : (
				<div className="container mx-auto px-8 pb-2 pt-7">
					<div className="mb-6">
						<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
							Integrations
						</div>
						<div className="font-400 max-w-3xl font-inter text-sm leading-5 text-muted-foreground">
							If you use one of these services, we recommend integrating them
							with CodeRabbit. This will allow CodeRabbit to use the context
							from the linked issues while reviewing the code. New workflow
							integrations are in progress and will be added upon availability.
						</div>
					</div>

					<div className="flex flex-col flex-wrap gap-6 sm:flex-row">
						{integrations.map(integration => (
							<div
								key={integration.name}
								className="flex-1 rounded-lg border p-6 sm:min-w-60 sm:max-w-4xl"
							>
								<div className="flex justify-between">
									<div className="flex flex-wrap items-center gap-4">
										<div className="rounded-lg border p-1.5">
											<img
												src={integration.logo}
												className="w-12 min-w-12"
												alt={`${integration.name} Logo`}
											/>
										</div>
										<div>
											<div className="flex items-center gap-2">
												<h2 className="font-weight-600 text-lg text-crb-text-primary">
													{integration.name}
												</h2>
												{integration.invalidAuth && (
													<Tooltip>
														<TooltipTrigger asChild>
															<span className="inline-block cursor-help">
																<FaExclamationTriangle className="text-sm text-yellow-600" />
															</span>
														</TooltipTrigger>
														<TooltipContent>
															Something went wrong with the authorization.{" "}
															<br /> Please re-authenticate with{" "}
															{integration.name}
														</TooltipContent>
													</Tooltip>
												)}
											</div>
											{integration.name === "Jira" && jiraSelfHostedURL && (
												<div className="text-sm text-muted-foreground">
													{jiraSelfHostedURL}
												</div>
											)}
										</div>
									</div>
									{integration.showLoader ? (
										<CgSpinner className="animate-spin text-xl text-crb-primary-dark" />
									) : (
										<Tooltip>
											<TooltipTrigger asChild>
												<div className="inline-block">
													<Switch
														checked={integration.authorized}
														onCheckedChange={(checked: boolean) =>
															handleToggleIntegration(integration, checked)
														}
														disabled={!isAdmin && integration.authorized}
													/>
												</div>
											</TooltipTrigger>
											{integration.authorized && !isAdmin && (
												<TooltipContent>
													{selectedOrg?.type === "gitlab"
														? `This operation requires admin privileges in GitLab. Your current GitLab role is ${role}.`
														: `This operation requires admin privileges in GitHub. Your current GitHub role is ${role}.`}
												</TooltipContent>
											)}
										</Tooltip>
									)}
								</div>
								<div className="mt-6 font-poppins text-crb-text-tertiary">
									{integration.description}
								</div>
								{integration.name === "Jira" && !integration.authorized && (
									<div>
										<Button
											variant="link"
											className="px-0 text-muted-foreground"
											onClick={() => {
												setOpenSelfHostedModal(true)
											}}
										>
											Using Jira Data Center (Self-Hosted)?
										</Button>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			)}
			<JiraSelfHostedModal
				open={openSelfHostedModal}
				onOpenChange={setOpenSelfHostedModal}
				onSaveCreds={(host_url, pat) => {
					void saveIntegration(
						{ host_url, pat, service: "Jira" },
						setIsJiraAuthorized,
						setIsJiraIntegrationSaved,
					)
				}}
			/>
			<CircleCIModal
				open={openCircleCIModal}
				onOpenChange={setOpenCircleCIModal}
				onSaveToken={tokenData => {
					void saveIntegration(
						{
							service: "CIRCLECI",
							...tokenData,
						},
						setIsCircleCIAuthorized,
						setIsCircleCIIntegrationSaved,
					)
				}}
			/>
		</>
	)
}
