import { trpc } from "@/trpc"
import { useInbox } from "@trycourier/react-hooks"
import { CourierProvider } from "@trycourier/react-provider"
import moment from "moment"
import { useEffect, useState } from "react"
import { LuBell } from "react-icons/lu"
import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Skeleton } from "../ui/skeleton"
import { useCheckProPlanLock } from "./nav-utils"
import { useSelectedOrg } from "./useSelectedOrg"

const NotificationSkeleton = () => {
	return (
		<div className="flex flex-col gap-2">
			<div className="border-b px-4 pb-2 font-medium">Notifications</div>
			<div className="flex flex-col gap-2">
				{Array.from({ length: 5 }).map((_, index) => (
					<Skeleton key={index} className="h-8 w-full rounded-none" />
				))}
			</div>
		</div>
	)
}

interface NotificationProps {
	readonly collapsed: boolean
}

export const Notifications: React.FC<NotificationProps> = ({ collapsed }) => {
	const organization = useSelectedOrg()
	const { proPlanLock } = useCheckProPlanLock()

	const { data, isLoading, error } =
		trpc.reporting.getInboxAuthorizationToken.useQuery(undefined, {
			enabled: !!organization?.id && !proPlanLock,
		})

	if (proPlanLock) {
		return null
	}

	return (
		<Popover>
			<PopoverTrigger asChild>
				{collapsed ? (
					<Button variant="outline" className="mt-2 w-full px-3">
						<LuBell className="mr-2" />
						Notifications
					</Button>
				) : (
					<Button variant="ghost" className="px-3">
						<LuBell />
					</Button>
				)}
			</PopoverTrigger>
			<PopoverContent className="z-20 px-0">
				{isLoading && <NotificationSkeleton />}
				{!isLoading && (
					<CourierProvider
						userId={organization?.id ?? ""}
						clientKey={import.meta.env.VITE_COURIER_CLIENT_KEY}
						authorization={data?.token ?? ""}
					>
						<NotificationsContent
							errorMessage={
								error?.data?.code === "UNAUTHORIZED"
									? "Notifications are available only for paid plans"
									: ""
							}
						/>
					</CourierProvider>
				)}
			</PopoverContent>
		</Popover>
	)
}

export const NotificationsContent: React.FC<{
	readonly errorMessage: string
}> = ({ errorMessage }) => {
	const inbox = useInbox()

	const [messages, setMessages] = useState<
		ReturnType<typeof useInbox>["messages"]
	>([])

	useEffect(() => {
		if (!errorMessage) {
			inbox.fetchMessages({
				params: {
					limit: 5,
				},
			})
		}
	}, [errorMessage])

	useEffect(() => {
		if (inbox.messages && !inbox.isLoading) {
			setMessages(inbox.messages)
		}
	}, [inbox.messages, inbox.isLoading])

	const isLoading = inbox.isLoading && messages?.length === 0

	return (
		<div>
			<div className="flex flex-col gap-2">
				<div className="border-b px-4 pb-2 font-medium">Notifications</div>
				{errorMessage && (
					<div className="flex h-60 items-center justify-center p-4 text-center text-muted-foreground">
						{errorMessage}
					</div>
				)}
				{!errorMessage && isLoading && (
					<div className="flex flex-col gap-2">
						{Array.from({ length: 5 }).map((_, index) => (
							<Skeleton key={index} className="h-8 w-full rounded-none" />
						))}
					</div>
				)}
				{!errorMessage &&
					!isLoading &&
					(messages?.length === 0 ? (
						<div className="flex h-60 items-center justify-center p-4 text-muted-foreground">
							No notifications yet
						</div>
					) : (
						<div className="flex flex-col">
							{messages?.map(message => {
								return (
									<Link
										to={`/report/details/${message.messageId}`}
										className="px-4 py-2 hover:bg-muted"
										key={message.messageId}
									>
										<div className="flex w-full items-center justify-between gap-4">
											<div className="flex min-w-0">
												<div className="flex items-center gap-2 text-sm">
													{message.title}
												</div>
											</div>
											<span className="flex-shrink-0 text-xs text-muted-foreground">
												{moment(message.created).fromNow(true)}
											</span>
										</div>
									</Link>
								)
							})}
						</div>
					))}
			</div>
		</div>
	)
}
