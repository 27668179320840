import Search from "@/components/Search/Search"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Skeleton } from "@/components/ui/skeleton"
import { cn } from "@/lib/utils"
import { trpc } from "@/trpc"
import { getSelectedOrg } from "@/utils/utils"
import { ChevronDown } from "lucide-react"
import { useState, type FC } from "react"
import type { SelectedGitlabGroup } from "./RepositoryList"

const GITLAB_SEARCH_LIMIT = 20

export const GitlabGroupSelector: FC<{
	value: SelectedGitlabGroup
	onSelect: (subgroup: SelectedGitlabGroup) => void
}> = ({ value, onSelect }) => {
	const selectedOrg = getSelectedOrg()
	const orgName = selectedOrg?.organization_name ?? ""

	const [searchQuery, setSearchQuery] = useState("")
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const { data: results, isLoading: isLoadingSearch } =
		trpc.repositories.searchGitlabGroups.useQuery(
			{
				searchQuery: searchQuery ? searchQuery : orgName,
				page: 1,
				limit: GITLAB_SEARCH_LIMIT,
			},
			{
				select: data => {
					const results = data.data.filter(org =>
						org.full_path.startsWith(orgName),
					)

					return {
						data: results,
						count: results.length,
					}
				},
			},
		)

	if (!selectedOrg || !value) return <Skeleton className="h-8 w-48" />

	return (
		<>
			<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
				<DropdownMenuTrigger
					className="rounded-md
						focus:outline-none focus:ring-4 focus:ring-gray-300"
				>
					<div
						aria-label="Search"
						className={cn(
							`flex-1 rounded-md border border-gray-300 bg-white px-3
						py-1.5 font-figtreeRegular shadow-sm transition placeholder:text-gray-400 focus:outline-none
						focus:ring-4 focus:ring-gray-300`,
							"min-w-48 text-left",
							"flex flex-row items-center justify-between gap-2",
						)}
					>
						<span className="shrink">{value.path || value.name}</span>
						<ChevronDown className="h-4 w-4 shrink-0" />
					</div>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<div
						className="flex flex-col gap-3 p-1.5"
						onClick={e => {
							e.stopPropagation()
						}}
						onKeyDown={e => {
							e.stopPropagation()
						}}
					>
						<Search
							defaultValue={searchQuery}
							onSearch={val => {
								setSearchQuery(val)
							}}
							debounce
							showLabel={false}
							fullWidth={true}
							placeholder="Subgroup not found? Search here..."
						/>
						{isLoadingSearch && (
							<div className="flex flex-col gap-1.5">
								{Array(3)
									.fill(0)
									.map((_, idx) => {
										return <Skeleton key={idx} className="h-7" />
									})}
							</div>
						)}
						{!isLoadingSearch &&
							(results?.data.length ? (
								<div className="flex flex-col gap-0.5">
									{results.data
										.sort((a, b) => {
											return a.full_path.localeCompare(b.full_path)
										})
										.map(group => {
											const isActive = value.id === group.id.toString()
											return (
												<DropdownMenuItem
													key={group.full_path}
													onClick={() => {
														onSelect({
															id: `${group.id}`,
															name: group.name,
															path: group.full_path,
														})
														setDropdownOpen(false)

														// reset searchQuery
														setSearchQuery("")
													}}
													className={cn(isActive && "bg-accent", "text-base")}
												>
													{group.full_path}
												</DropdownMenuItem>
											)
										})}
								</div>
							) : (
								<div className="flex flex-col gap-0.5">No groups found</div>
							))}
					</div>
				</DropdownMenuContent>
			</DropdownMenu>
		</>
	)
}
