import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../../../../components/Loader/Loader"
import LogoFull from "../../../../svg/logo-full"
import { trpc } from "@/trpc.ts"

const SlackAppInstall: React.FC = () => {
	const navigate = useNavigate()
	const { search } = useLocation()

	const [hasError, setHasError] = React.useState<boolean>(false)

	const saveSlackConnectionReq =
		trpc.reporting.saveSlackConnection.useMutation()

	useEffect(() => {
		const params = new URLSearchParams(search)
		const code = params.get("code")
		const token = params.get("state")

		if (code && token) {
			saveSlackConnectionReq
				.mutateAsync({
					code,
					token,
				})
				.then(() => {
					navigate("/reports/recurring/new")
				})
				.catch(error => {
					setHasError(true)
					toast.error(
						"Failed to save slack connection: " +
							error.response?.data?.message || error,
					)
				})
		}
	}, [])

	if (hasError) {
		return (
			<div className="flex h-screen w-screen flex-col items-center justify-center ">
				<LogoFull width={350} />
				<h1 className="mt-4 font-poppins text-lg text-[#242424] sm:text-xl">
					Failed to connect to Slack. Please try again.
				</h1>
			</div>
		)
	}

	return <Loader message="Connecting Slack..." />
}

export default SlackAppInstall
