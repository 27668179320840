import type { TRPCClientError } from "@trpc/client"
import type { AxiosError } from "axios"
import type { AppRouter } from "../../../coderabbitHandler/src/routers"
import { isTRPCClientError } from "./utils"

type ErrorType = AxiosError | Error | TRPCClientError<AppRouter> | unknown

export function formatErrorDetails(error: ErrorType): Record<string, unknown> {
	// For Axios errors
	if ((error as AxiosError).isAxiosError) {
		const axiosError = error as AxiosError
		return {
			type: "AxiosError",
			message: axiosError.message,
			response: axiosError.response
				? {
						status: axiosError.response.status,
						data: axiosError.response.data,
						headers: axiosError.response.headers,
					}
				: null,
			request: {
				url: axiosError.config?.url,
				method: axiosError.config?.method,
				headers: axiosError.config?.headers,
			},
		}
	}

	// For tRPC errors
	if (isTRPCClientError(error)) {
		const trpcError = error
		return {
			type: "TRPCError",
			message: trpcError.message,
			data: {
				code: trpcError.data?.code,
				status: trpcError.data?.httpStatus,
				stack: trpcError.data?.stack,
				path: trpcError.data?.path,
			},
			cause: trpcError.cause,
			stack: trpcError.stack,
		}
	}

	// Fallback for generic errors
	return {
		type: "Error",
		message:
			error &&
			typeof error === "object" &&
			"message" in error &&
			typeof error.message === "string"
				? error.message
				: "Unknown error",
		stack:
			error && typeof error === "object" && "stack" in error
				? error.stack
				: null,
	}
}
