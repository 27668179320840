import { Button } from "@/components/ui/button.tsx"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { logger } from "@/lib/utils"
import { loginRequest, msalConfig } from "@/pages/Login/configs/azureDevOps.ts"
import { trpc } from "@/trpc.ts"
import type { GetUserDataResponse, SelfHostedDomainData } from "@/typings"
import type { BillingHandlerCheckAndCreateUserResp } from "@/typings/billingHandler.ts"
import type {
	CRHandlerCreateOrganizationsBody,
	CRHandlerCreateOrganizationsResp,
} from "@/typings/coderabbitHandler.ts"
import { formatErrorDetails } from "@/utils/error-logs.ts"
import { addGrowsurfParticipant } from "@/utils/growsurf-util.ts"
import { PROVIDERS, useProvider } from "@/utils/providers"
import { getCookie, sentryCaptureException } from "@/utils/utils.ts"
import * as msal from "@azure/msal-browser"
import MarkunreadIcon from "@mui/icons-material/Markunread"
import type { AxiosResponse } from "axios"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { FaCog } from "react-icons/fa"
import { SiAzuredevops, SiBitbucket, SiGithub, SiGitlab } from "react-icons/si"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import validator from "validator"
import Loader from "../../components/Loader/Loader"
import LogoFull from "../../svg/logo-full"
import { generateMergeFields, useEmailSubmitHandler } from "./emailHandler.ts"
import { FooterText } from "./FooterText"
import {
	GITHUB_SELF_HOSTED_LS_KEY,
	GITLAB_SELF_HOSTED_LS_KEY,
} from "./selfhosted"
import { createTrackingCookies, getQueryParams } from "./trackingCookies"

const GITHUB_CLIENT_ID = import.meta.env.VITE_GITHUB_OAUTH_APP_CLIENT_ID
const GITLAB_CLIENT_ID = import.meta.env.VITE_GITLAB_CLIENT_ID
const BITBUCKET_CLIENT_ID = import.meta.env.VITE_BITBUCKET_CLIENT_ID
const ENABLE_AZURE_DEVOPS = import.meta.env.VITE_ENABLE_AZURE_DEVOPS === "true"
const ENABLE_BITBUCKET = import.meta.env.VITE_ENABLE_BITBUCKET === "true"

const azurePublicClientApp = new msal.PublicClientApplication(msalConfig)

export function Login({ isCRSelfHostedLogin = false }) {
	const navigate = useNavigate()
	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const [isTrialUser, setIsTrialUser] = useState<boolean>(false)
	const [showEmailInput, setShowEmailInput] = useState<boolean>(false)
	const [email, setEmail] = useState<string>("")
	const [isValidEmail, setIsValidEmail] = useState<boolean>(true)
	const [showCheckmark, setShowCheckmark] = useState<boolean>(false)
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
	const [codeParameter, setCodeParameter] = useState<string>("")
	const [stateParameter, setStateParameter] = useState<string>("")
	const [tab, setTab] = useState<"saas" | "selfhosted">(
		isCRSelfHostedLogin ? "selfhosted" : "saas",
	)
	const [gitlabSelfHosted, setGitlabSelfHosted] =
		useState<SelfHostedDomainData>()
	const [gitHubSelfHosted, setGitHubSelfHosted] =
		useState<SelfHostedDomainData>()
	const { provider, setProvider, isCRSelfHosted } = useProvider()

	const trpcUtils = trpc.useUtils()

	const addHubspotContact = trpc.hubspot.addContact.useMutation()
	const updateUserAdditionalEmails =
		trpc.hubspot.updateUserAdditionalEmails.useMutation()

	function handleEmailInputVisibility() {
		setShowEmailInput(true)
	}

	function handleEmailInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		const inputEmail = event.target.value
		setEmail(inputEmail)
		setIsValidEmail(validator.isEmail(inputEmail))
		setIsSubmitted(false)
	}

	const { handleEmailSubmit } = useEmailSubmitHandler()

	async function handleSubmit() {
		await handleEmailSubmit(
			email,
			isValidEmail,
			setShowEmailInput,
			setIsSubmitted,
			setShowCheckmark,
		)
	}

	useEffect(() => {
		setTab(isCRSelfHostedLogin ? "selfhosted" : "saas")
	}, [isCRSelfHostedLogin])

	function setupGitHubSelfHosted() {
		const gitHubSelfHostedLS = localStorage.getItem(GITHUB_SELF_HOSTED_LS_KEY)

		const gitHubSelfHostedData = gitHubSelfHostedLS
			? (JSON.parse(gitHubSelfHostedLS) as SelfHostedDomainData)
			: null

		if (gitHubSelfHostedData) {
			setGitHubSelfHosted(gitHubSelfHostedData)
			sessionStorage.setItem("selfHostedDomain", gitHubSelfHostedData.hostURL)
		}
	}

	function setupGitLabSelfHosted() {
		const gitlabSelfHostedLS = localStorage.getItem(GITLAB_SELF_HOSTED_LS_KEY)

		const gitlabSelfHostedData = gitlabSelfHostedLS
			? (JSON.parse(gitlabSelfHostedLS) as SelfHostedDomainData)
			: null

		if (gitlabSelfHostedData) {
			setGitlabSelfHosted(gitlabSelfHostedData)
			sessionStorage.setItem("selfHostedDomain", gitlabSelfHostedData.hostURL)
		}
	}

	function navigateTo(path: string, email?: string) {
		navigate(isCRSelfHosted ? "/settings/account/subscription" : path, {
			state: { email },
		})
	}

	const [searchParams] = useSearchParams()
	const client = searchParams.get("client")

	useEffect(() => {
		if (client) {
			localStorage.setItem("client", client)
			localStorage.setItem("client_state", searchParams.get("state") || "")
			localStorage.setItem(
				"client_redirect_uri",
				searchParams.get("redirect_uri") || "",
			)
		}
	}, [client])

	// Helper functions for VSCode redirection logic
	const isVSCodeRedirectNeeded = (client: string | null) =>
		!client && localStorage.getItem("client") === "vscode"

	const getRedirectUri = (provider: string) =>
		provider === PROVIDERS.GITLAB ||
		provider === PROVIDERS.GITLAB_SELF_HOSTED ||
		provider === PROVIDERS.GITHUB_SELF_HOSTED ||
		provider === PROVIDERS.BITBUCKET ||
		provider === PROVIDERS.AZURE_DEVOPS
			? `${window.location.origin}/login`
			: ""

	const constructVSCodeUrl = (code: string | null, provider: string) => {
		const vsCodeUrl = new URL(localStorage.getItem("client_redirect_uri") || "")
		vsCodeUrl.searchParams.set("code", code || "")
		vsCodeUrl.searchParams.set(
			"state",
			localStorage.getItem("client_state") || "",
		)
		vsCodeUrl.searchParams.set("provider", provider)
		vsCodeUrl.searchParams.set(
			"selfHostedDomain",
			sessionStorage.getItem("selfHostedDomain") || "",
		)
		vsCodeUrl.searchParams.set("redirect_uri", getRedirectUri(provider))
		return vsCodeUrl
	}

	useEffect(() => {
		if (isVSCodeRedirectNeeded(client)) {
			localStorage.removeItem("client")
			const code = searchParams.get("code")
			const provider = searchParams.get("state")

			const vsCodeUrl = constructVSCodeUrl(code, provider || "")
			toast.success("Redirecting to VS Code...")
			window.location.href = vsCodeUrl.toString()
			navigate("/login/vscode-extension")

			return
		}

		const {
			trialParams,
			codeParams,
			stateParam,
			bitbucket,
			grsf,
			...trackingQueryParams
		} = getQueryParams()

		// Handle tracking cookies
		createTrackingCookies({ ...trackingQueryParams, grsf, bitbucket })

		// Update state based on query params
		if (trialParams) setIsTrialUser(true)
		if (codeParams) setCodeParameter(codeParams)
		if (stateParam) setStateParameter(stateParam)

		// Setup self-hosted configurations
		setupGitHubSelfHosted()
		setupGitLabSelfHosted()

		const sessionAccessToken = sessionStorage.getItem("accessToken")

		if (
			!sessionAccessToken &&
			sessionStorage.getItem("login-loader") != null &&
			sessionStorage.getItem("login-loader") == "true" &&
			provider === PROVIDERS.AZURE_DEVOPS &&
			!codeParameter
		) {
			setLoader(true)
			setLoaderMessage("Authorizing your login... 🚀")

			void azurePublicClientApp.initialize().then(() => {
				const accounts = azurePublicClientApp.getAllAccounts()
				if (accounts.length && accounts[0]) {
					void azurePublicClientApp
						.acquireTokenSilent({
							...loginRequest,
							account: accounts[0],
						})
						.then(resp => {
							if (resp.accessToken) {
								sessionStorage.setItem("tenantId", resp.tenantId)
								setCodeParameter(resp.accessToken)
							}
						})
						.catch((error: unknown) => {
							// If silent token acquisition fails, redirect to login page
							if (error instanceof msal.InteractionRequiredAuthError) {
								sessionStorage.setItem("login-loader", "false")
							} else {
								const errorDetails = formatErrorDetails(error)
								sentryCaptureException(
									"Error acquiring token silently: ",
									errorDetails,
								)
								console.error("Error acquiring token silently:", error)
							}
						})
				} else {
					void azurePublicClientApp
						.handleRedirectPromise()
						.then(resp => {
							if (!resp) {
								return
							}

							if (resp.accessToken) {
								sessionStorage.setItem("tenantId", resp.tenantId)
								setCodeParameter(resp.accessToken)
							}
						})
						.catch(error => {
							const errorDetails = formatErrorDetails(error)
							sentryCaptureException(
								"Error handling redirect promise: ",
								errorDetails,
							)
							console.error("Error handling redirect promise: ", error)
						})
				}
			})
		} else if (codeParameter && sessionAccessToken === null) {
			if (
				sessionStorage.getItem("login-loader") != null &&
				sessionStorage.getItem("login-loader") == "true"
			) {
				setLoader(true)
				setLoaderMessage("Authorizing your login... 🚀")
			}

			void getAccessToken()
		} else if (
			sessionStorage.getItem("accessToken") !== null &&
			sessionStorage.getItem("subscriber_id") !== null
		) {
			let userEmail = ""
			async function fetchUserData() {
				const { user_email } = await getUserData()
				userEmail = user_email
			}

			void fetchUserData()
			navigateTo("/settings/repositories", userEmail)
		}
	}, [codeParameter, stateParameter])

	async function getAccessToken() {
		setLoader(true)
		setLoaderMessage("Authorizing your login... 🚀")
		const redirectUri =
			stateParameter === PROVIDERS.GITLAB ||
			stateParameter === PROVIDERS.GITLAB_SELF_HOSTED ||
			stateParameter === PROVIDERS.GITHUB_SELF_HOSTED ||
			stateParameter === PROVIDERS.BITBUCKET ||
			provider === PROVIDERS.AZURE_DEVOPS
				? `${window.location.origin}/login`
				: ""

		const selfHostedDomain =
			stateParameter === PROVIDERS.GITHUB_SELF_HOSTED
				? gitHubSelfHosted?.hostURL
				: gitlabSelfHosted?.hostURL

		try {
			const { data, status } = await trpcUtils.accessToken.getAccessToken.fetch(
				{
					provider: stateParameter || provider || "",
					redirectUri: redirectUri,
					code: codeParameter,
					selfHostedDomain: selfHostedDomain ?? undefined,
				},
				{},
			)

			if (status !== 200 && provider === PROVIDERS.AZURE_DEVOPS) {
				navigateTo("/application-consent")
				return
			}

			if (status !== 200) {
				toast.error("Something went wrong, Please try again")
				setLoader(false)
				return
			}

			// TODO: Remove auth-token since it's the same as accessToken
			sessionStorage.setItem("accessToken", data.token)
			sessionStorage.setItem("auth-token", data.token)
			setProvider((stateParameter || provider) as typeof provider)

			const { recently_created, user_email, secondary_emails } =
				await getUserData()
			const orgExists = await checkOrg()
			if (!orgExists) await createOrg()

			let navigateToPage = ""

			if (recently_created) {
				// only add the user to hubspot for PROD
				const utmParams = generateMergeFields(false)
				await addHubspotContact
					.mutateAsync({
						email: user_email,
						provider: stateParameter,
						utmParams: {
							...utmParams,
						},
					})
					.catch(error => {
						console.error("Error adding Hubspot contact:", error)
					})
				navigateToPage = "/onboarding"
			} else {
				navigateToPage = "/settings/repositories"
			}

			if (secondary_emails.length > 0) {
				await updateUserAdditionalEmails
					.mutateAsync({
						email: user_email,
						additional_emails: secondary_emails,
					})
					.catch((error: unknown) => {
						console.error("Error updating Hubspot contact", {
							error: error,
							recently_created: recently_created,
							email: user_email,
							secondary_emails: secondary_emails,
						})
					})
			}
			navigateTo(navigateToPage, user_email)
		} catch (error) {
			if (
				provider === PROVIDERS.AZURE_DEVOPS &&
				sessionStorage.getItem("tenantId")
			) {
				navigateTo("/application-consent")
				return
			}

			toast.error("Something went wrong, Please try again")
		} finally {
			setLoader(false)
		}
	}

	async function getUserData(): Promise<GetUserDataResponse> {
		setLoaderMessage("Setting up CodeRabbit... 🚀")

		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		const stateParam = sessionStorage.getItem("provider")?.replace(/"/g, "")

		// Retrieve `grsf` from cookies
		const grsf = getCookie("grsf")

		const baseURL = new URL(
			`${import.meta.env.VITE_BILLING_FUNC_URL}/checkAndCreateUser`,
		)
		const params = new URLSearchParams({
			provider: stateParam || "",
		})

		if (
			stateParam === PROVIDERS.GITLAB_SELF_HOSTED ||
			stateParam === PROVIDERS.GITHUB_SELF_HOSTED
		) {
			params.append("selfHostedDomain", selfHostedDomain || "")
		}

		if (grsf) {
			params.append("grsf", grsf)
		}

		params.forEach((value, key) => {
			baseURL.searchParams.append(key, value)
		})

		try {
			const response = await fetch(baseURL, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("accessToken") || ""}`,
				},
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const { data } = (await response.json()) as {
				data: BillingHandlerCheckAndCreateUserResp["data"]
			}

			// Store necessary data in sessionStorage
			sessionStorage.setItem("user_id", data.provider_user_id)
			sessionStorage.setItem("login", data.user_name)
			sessionStorage.setItem("subscriber_id", data.id)
			sessionStorage.setItem("scope", data.scope)
			sessionStorage.setItem(
				"recently_created",
				data.recently_created.toString(),
			)

			// Handle avatar URL based on provider
			if (stateParam === PROVIDERS.AZURE_DEVOPS) {
				// Azure DevOps provides Base64 encoded avatar_url
				sessionStorage.setItem(
					"avatar_url",
					`data:image/png;base64, ${data.avatar_url}`,
				)
			} else {
				sessionStorage.setItem("avatar_url", data.avatar_url)
			}

			// Add participant to Growsurf
			if (data.email) {
				try {
					await addGrowsurfParticipant(data.email, data.name || data.user_name)
				} catch (error) {
					logger.error("Failed to add GrowSurf participant:", error)
					// Don't block the login flow on GrowSurf failure
				}
			}

			// Identify user with your analytics tool
			window.ko?.identify(data.email, {
				name: data.name,
				provider: data.provider,
				scope: data.scope,
				secondary_emails: data.secondary_emails.join(";"),
			})
			return {
				recently_created: data.recently_created,
				user_email: data.email,
				secondary_emails: data.secondary_emails,
				user_name: data.name,
			}
		} catch (error) {
			logger.error("Error fetching user data:", error)
			throw error
		}
	}
	async function checkOrg(): Promise<boolean> {
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")
		const provider = sessionStorage.getItem("provider")?.replace(/"/g, "")
		const user_id = sessionStorage.getItem("user_id")

		if (!user_id || !provider) {
			return false
		}

		try {
			const response = await trpcUtils.organizations.checkOrganization.fetch({
				provider_organization_id: user_id,
				provider: provider,
				selfHostedDomain: selfHostedDomain ?? undefined,
			})
			return response.isSuccess
		} catch {
			return false
		}
	}

	const createOrg = async () => {
		const selfHostedDomain = sessionStorage.getItem("selfHostedDomain")

		const organization_name = sessionStorage.getItem("login")
		const provider = sessionStorage.getItem("provider")?.replace(/"/g, "")
		const provider_organization_id = sessionStorage.getItem("user_id")
		const scope = sessionStorage.getItem("scope")

		if (!organization_name || !provider_organization_id) {
			return
		}

		const body: CRHandlerCreateOrganizationsBody = {
			organization_name,
			provider_organization_id,
			provider: provider || "",
			scope,
			provider_user_id: provider_organization_id, // orgId and userId is same at login page
			memberCount: 1, // user account/personal org
		}

		if (
			provider === PROVIDERS.GITLAB_SELF_HOSTED ||
			provider === PROVIDERS.GITHUB_SELF_HOSTED
		) {
			body.selfHostedDomain = selfHostedDomain
		}

		await axios
			.post<
				CRHandlerCreateOrganizationsResp,
				AxiosResponse<CRHandlerCreateOrganizationsResp>,
				CRHandlerCreateOrganizationsBody
			>(
				`${import.meta.env.VITE_CODERABBIT_FUNC_URL}/createOrganizations`,
				body,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("auth-token")}`,
					},
				},
			)
			.then(() => {
				sessionStorage.removeItem("scope")
			})
			.catch(error => {
				const errorDetails = formatErrorDetails(error)
				sentryCaptureException("createOrg: API failed: ", errorDetails)
			})
	}

	function loginWithGitHub() {
		sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
		sessionStorage.setItem("login-loader", "true")
		const scope = "user:email read:org"
		const encodedScope = encodeURIComponent(scope)
		const redirectUri = `${window.location.origin}/login`
		window.location.assign(
			`https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&scope=${encodedScope}&state=${PROVIDERS.GITHUB}&redirect_uri=${redirectUri}`,
		)
	}

	function loginWithGitlab() {
		sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
		sessionStorage.setItem("login-loader", "true")
		const scope = "read_user+read_repository+api+openid"
		const redirectUri = `${window.location.origin}/login`
		window.location.assign(
			`https://gitlab.com/oauth/authorize?client_id=${GITLAB_CLIENT_ID}&scope=${scope}&response_type=code&redirect_uri=${redirectUri}&state=${PROVIDERS.GITLAB}`,
		)
	}

	function loginWithSelfHostedGitlab() {
		sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
		if (gitlabSelfHosted?.hostURL) {
			const { hostURL, clientId, scope, redirectURI } = gitlabSelfHosted
			window.location.assign(
				`${hostURL}/oauth/authorize?client_id=${clientId}&scope=${scope}&response_type=code&redirect_uri=${redirectURI}&state=${PROVIDERS.GITLAB_SELF_HOSTED}`,
			)
		} else {
			navigate("/login/gitlab-self-hosted")
		}
	}

	function loginWithSelfHostedGitHub() {
		sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
		if (gitHubSelfHosted?.hostURL) {
			const { hostURL, clientId, scope, redirectURI } = gitHubSelfHosted
			sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
			window.location.assign(
				`${hostURL}/login/oauth/authorize?client_id=${clientId}&scope=${scope}&response_type=code&redirect_uri=${redirectURI}&state=${PROVIDERS.GITHUB_SELF_HOSTED}`,
			)
		} else {
			navigate("/login/github-self-hosted")
		}
	}

	async function loginWithAzureDevops() {
		try {
			sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
			sessionStorage.setItem("login-loader", "true")
			setProvider(PROVIDERS.AZURE_DEVOPS)

			await azurePublicClientApp.initialize()
			await azurePublicClientApp.loginRedirect(loginRequest)
		} catch (e) {
			console.error("Error during Azure DevOps login: ", e)
			toast.error("Failed to initiate Azure DevOps login. Please try again.")
		}
	}

	function loginWithBitbucketCloud() {
		sessionStorage.setItem("isCRSelfHosted", isCRSelfHostedLogin.toString())
		sessionStorage.setItem("login-loader", "true")

		window.location.assign(
			`https://bitbucket.org/site/oauth2/authorize?client_id=${BITBUCKET_CLIENT_ID}&response_type=code&state=${PROVIDERS.BITBUCKET}`,
		)
	}

	return (
		<>
			{loader ? (
				<Loader message={loaderMessage} />
			) : (
				<div className="flex h-screen flex-col items-center justify-center bg-secondary">
					<div className="mx-4 flex w-full max-w-xl flex-col items-center rounded-md bg-white text-center shadow-lg sm:mx-auto">
						<div className="px-4 py-16">
							<div className="flex items-center justify-center pb-5">
								<LogoFull width={171} />
							</div>

							<div className="mb-4 mt-2">
								<div className="font-500 mb-2 font-poppins text-2xl leading-8 text-foreground">
									Welcome to CodeRabbit {isCRSelfHostedLogin && "Self-Hosted"}
								</div>
								{isTrialUser && !isCRSelfHostedLogin && (
									<div className="font-400 mx-auto max-w-md font-poppins text-sm leading-5 text-muted-foreground">
										Get a 14-day free trial for your entire team by signing up
										with your Git provider.
									</div>
								)}
							</div>

							<Tabs
								value={tab}
								onValueChange={newTab => {
									setTab(newTab as typeof tab)
								}}
							>
								{!isCRSelfHostedLogin && (
									<TabsList className="mb-3 w-full max-w-80">
										<TabsTrigger className="flex-1" value="saas">
											SaaS
										</TabsTrigger>
										<TabsTrigger className="flex-1" value="selfhosted">
											Self-Hosted
										</TabsTrigger>
									</TabsList>
								)}
								<TabsContent value="saas">
									<Button
										onClick={loginWithGitHub}
										variant="outline"
										className="w-full max-w-80 text-secondary-foreground"
									>
										<SiGithub className="mr-2 flex-shrink-0" size={20} />
										{isTrialUser ? "Sign up with GitHub" : "Login with GitHub"}
									</Button>

									<Button
										onClick={loginWithGitlab}
										variant="outline"
										className="mt-3 w-full max-w-80 text-secondary-foreground"
									>
										<SiGitlab className="mr-2 flex-shrink-0" size={20} />
										{isTrialUser ? "Sign up with GitLab" : "Login with GitLab"}
									</Button>

									{ENABLE_AZURE_DEVOPS ? (
										<Button
											onClick={loginWithAzureDevops}
											variant="outline"
											className="mt-3 w-full max-w-80 text-secondary-foreground"
										>
											<SiAzuredevops className="mr-2 flex-shrink-0" size={20} />
											{isTrialUser
												? "Sign up with Azure DevOps"
												: "Login with Azure DevOps"}
										</Button>
									) : null}

									{ENABLE_BITBUCKET ? (
										<Button
											onClick={loginWithBitbucketCloud}
											variant="outline"
											className="mt-3 w-full max-w-80 text-secondary-foreground"
										>
											<SiBitbucket className="mr-2 flex-shrink-0" size={20} />
											{isTrialUser
												? "Sign up with Bitbucket Cloud (Beta)"
												: "Login with Bitbucket Cloud (Beta)"}
										</Button>
									) : null}
								</TabsContent>

								<TabsContent value="selfhosted">
									{import.meta.env.VITE_ENABLE_GITHUB_SELF_HOSTED && (
										<div className="relative mt-3">
											<Button
												onClick={loginWithSelfHostedGitHub}
												variant="outline"
												className="w-full max-w-80 text-secondary-foreground"
											>
												<SiGithub className="mr-2 flex-shrink-0" size={20} />
												{gitHubSelfHosted?.orgName ||
													"GitHub Enterprise Server"}
											</Button>
											{gitHubSelfHosted?.orgName && (
												<Button
													title="Configure your existing instance"
													variant="ghost"
													className="absolute ml-1 p-2.5 text-muted-foreground"
													onClick={() => {
														navigate("/login/github-self-hosted?configure")
													}}
												>
													<FaCog />
												</Button>
											)}
										</div>
									)}
									<div className="relative mt-3">
										<Button
											onClick={loginWithSelfHostedGitlab}
											variant="outline"
											className="w-full max-w-80 text-secondary-foreground"
										>
											<SiGitlab className="mr-2 flex-shrink-0" size={20} />
											{gitlabSelfHosted?.orgName || "Self-Hosted GitLab"}
										</Button>
										{gitlabSelfHosted?.orgName && (
											<Button
												title="Configure your existing instance"
												variant="ghost"
												className="absolute ml-1 p-2.5 text-muted-foreground"
												onClick={() => {
													navigate("/login/gitlab-self-hosted?configure")
												}}
											>
												<FaCog />
											</Button>
										)}
									</div>
								</TabsContent>
							</Tabs>

							{/* Only in mobile view, add a button to signup via email */}
							{isTrialUser && !isCRSelfHostedLogin && (
								<div className="sm:hidden">
									<p className="mt-6 text-gray-500">or</p>
									{!isValidEmail && isSubmitted && (
										<p className="text-xs text-red-500">
											Please enter a valid email address
										</p>
									)}
									{/* Display checkmark if the email is submitted */}
									{showCheckmark ? (
										<span
											role="img"
											aria-label="Checkmark"
											className="text-green-500"
										>
											&#10004; Submitted
										</span>
									) : !showEmailInput ? (
										<button
											onClick={handleEmailInputVisibility}
											className="btn-secondary btn-secondary-outline mt-5 inline-flex items-center justify-center rounded-full border border-transparent px-2 py-2 text-sm font-bold text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:px-2 md:py-2 lg:px-4 lg:py-2"
											style={{ width: "12rem" }}
											type="button"
										>
											<MarkunreadIcon className="text-white-600 mr-2 inline-block h-5 w-5 align-text-top" />
											Sign up later
										</button>
									) : null}
								</div>
							)}

							{/* Render email input when the button/link is clicked */}
							{showEmailInput && (
								<div className="sm:hidden">
									<input
										type="email"
										placeholder="Enter your email"
										value={email}
										onChange={handleEmailInputChange}
										className="mt-4 rounded border px-2 py-1"
									/>
									<button
										onClick={handleSubmit}
										className="btn-secondary btn-secondary-outline mt-5 inline-flex items-center justify-center rounded-full border border-transparent px-16 py-2 text-sm font-bold text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:px-2 md:py-2 lg:px-4 lg:py-2"
									>
										Submit
									</button>
								</div>
							)}
						</div>
						{import.meta.env.VITE_ENABLE_CODERABBIT_SELF_HOSTED &&
							!isCRSelfHostedLogin && (
								<>
									<hr className="w-full" />

									<p className="font-400 m-4 text-center text-sm text-foreground">
										Looking for{" "}
										<Link
											to="/login/coderabbit-self-hosted"
											className="text-crb-primary-dark hover:underline"
										>
											CodeRabbit Self-Hosted login
										</Link>
										?
									</p>
								</>
							)}
					</div>

					<FooterText />
				</div>
			)}
		</>
	)
}
export default Login
